/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PageHeader from '../../../components/PageHeader';
import BasicTable from '../../../components/Tables/BasicTable';
import withGetContainer from '../../../Container/withGetContainer';
import PaginationComponent from '../../../components/Common/Pagination';
import { handleSearchParams } from '../../../app/features/CommonSlice';
import { MAX_LENGTH } from '../../../Constant';
import { addPageLoadActivityLog, fullName, getModulePermission } from '../../../Util';
import { useHandleUpdateOrDeleteMutation } from '../../../app/features/api/CommonChangeApi';
import { Toast } from '../../../components/Common/Toaster';
import DocumentPopup from './DocumentPopup';

const BitcoinList = ({
    data, isLoading, isFetching,
}) => {
    const dispatch = useDispatch();
    getModulePermission('view', 'page');
    const updatePermission = getModulePermission('update', 'button');
    const deletePermission = getModulePermission('delete', 'button');
    const statusFilter = [
        { option: 'Approved', value: 'approved' },
        { option: 'Rejected', value: 'rejected' },
        { option: 'Pending', value: 'under_review' },
    ];
    const { searchParams } = useSelector((store) => store.common);
    const [documentModal, setDocumentModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState({});
    const [popup, setPopup] = useState(null);
    const [rejectComment, setRejectComment] = useState('');
    const [commentError, setCommentError] = useState('');
    const [handleUpdateOrDelete] = useHandleUpdateOrDeleteMutation();
    const [selectedDocument, setSelectedDocument] = useState('');
    const handleStatueChange = async () => {
        const statusArr = {};
        if (popup === 'reject') {
            if (rejectComment === '') {
                setCommentError('Comment is required.');
                return false;
            }
            statusArr.status = 'rejected';
            statusArr.rejected_comment = rejectComment;
        } else {
            statusArr.status = 'approved';
        }
        const responseData = await handleUpdateOrDelete({ url: `platformadmin/payments/${selectedStatus.id}`, method: 'PUT', data: statusArr });
        if (responseData?.data?.status === 200) {
            Toast({ type: 'success', message: responseData?.data?.message });
            setSelectedStatus({});
            window.scrollTo({ top: window.scrollY + window.scrollHeight, behavior: 'smooth' });
        } else {
            setSelectedStatus({});
        }
    };
    const handleComment = (event) => {
        if (event.target.value) {
            setRejectComment(event.target.value);
            setCommentError('');
        } else {
            setRejectComment('');
            setCommentError('Comment is required.');
        }
    };
    const columns = [
        {
            Header: 'DATE & TIME',
            accessor: 'createdAt',
        },
        {
            Header: 'ADVERTISER NAME',
            accessor: (row) => {
                const name = fullName(row.advertiser);
                return (
                    <p className="mb-0" title={name}>
                        {name ? (name.length >= MAX_LENGTH ? `${name?.substring(0, MAX_LENGTH)}...` : name) : ''}
                    </p>
                );
            },
        },
        {
            Header: 'AMOUNT',
            accessor: (row) => (
                <span>{`${row?.advertiser?.currency?.currency_symbol} ${row.amount}`}</span>
            ),
        },
        {
            Header: 'PLATFORM UUID',
            accessor: (originalRow) => (
                <p className="mb-0">
                    <span>{originalRow.uuid ? originalRow.uuid : '-'}</span>
                </p>
            ),
        },
        {
            Header: 'ATTACHED DOCUMENTS',
            accessor: (originalRow) => (
                <a
                    className="view-all"
                    href="javascript:;"
                    onClick={() => {
                        setDocumentModal(true);
                        setSelectedDocument(originalRow);
                    }}
                >
                    View Document
                </a>
            ),
        },
        {
            Header: 'ACTIONS',
            accessor: (originalRow) => (
                <div className="d-flex align-items-center gap-2 action-lists">
                    {(originalRow?.status === 'under_review' || originalRow?.status === 'approved') && (
                        <>
                            <div className={popup === 'approve' && selectedStatus?.id === originalRow.id ? 'show' : 'display-none'}>
                                <div className="kyc-reject-comment kyc-approve-comment">
                                    <div className="triangle" />
                                    <div className="comment-textbox">
                                        <p>Are you want to approve this payment
                                            <br />request ?
                                        </p>
                                    </div>
                                    <button type="button" className="btn btn-primary mr-3" onClick={handleStatueChange}>Yes</button>
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => {
                                            if (popup && selectedStatus?.id === originalRow?.id) {
                                                setSelectedStatus({});
                                                setRejectComment('');
                                                setCommentError('');
                                            }
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                            <button
                                type="button"
                                className={`btn-kyc kyc-status ${originalRow.status === 'approved' ? 'kyc-status-success pointer-events-none' : (selectedStatus?.id === originalRow?.id && popup === 'approve' ? 'approved' : 'approve')}`}
                                onClick={() => {
                                    if (originalRow?.status !== 'approved') {
                                        setPopup('approve');
                                        if (popup === 'approve' && selectedStatus?.id === originalRow?.id) {
                                            setRejectComment('');
                                            setPopup('');
                                            setCommentError('');
                                            setSelectedStatus({});
                                        } else {
                                            setCommentError('');
                                            setRejectComment('');
                                            setSelectedStatus(originalRow);
                                        }
                                    }
                                }}
                            >
                                <span className="text-capitalize">{originalRow.status === 'approved' ? 'Approved' : 'Approve'}</span>
                            </button>
                        </>
                    )}

                    {(originalRow?.status === 'under_review' || originalRow?.status === 'rejected') && (
                        <>
                            <div className={popup === 'reject' && selectedStatus?.id === originalRow.id ? 'show' : 'display-none'}>
                                <div className="kyc-reject-comment">
                                    <div className="triangle" />
                                    <div className="comment-textbox">
                                        <textarea placeholder="Write an appropriate reason here for rejecting the document." rows="4" value={rejectComment} onChange={handleComment} maxLength={200} />
                                        {commentError && (
                                            <p className="text-error">{commentError}</p>
                                        )}
                                        <p>An email will be sent to advertiser with the
                                            above mentioned Reason.
                                        </p>
                                    </div>
                                    <button type="button" className="btn btn-sm btn-primary mr-3" onClick={handleStatueChange}>Submit</button>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-outline-secondary"
                                        onClick={() => {
                                            if (popup && selectedStatus?.id === originalRow?.id) {
                                                setSelectedStatus({});
                                                setRejectComment('');
                                                setCommentError('');
                                            }
                                        }}
                                    >
                                        Cancel

                                    </button>
                                </div>
                            </div>
                            <button
                                type="button"
                                className={`btn-kyc kyc-status badge  ${originalRow.status === 'rejected' ? 'kyc-status-rejected pointer-events-none' : (selectedStatus?.id === originalRow?.id && popup === 'reject' ? 'rejected' : 'reject')}`}
                                onClick={() => {
                                    if (originalRow?.status === 'under_review') {
                                        setPopup('reject');
                                        if (popup === 'reject' && selectedStatus?.id === originalRow?.id) {
                                            setRejectComment('');
                                            setCommentError('');
                                            setPopup('');
                                            setSelectedStatus({});
                                        } else {
                                            setCommentError('');
                                            setRejectComment('');
                                            setSelectedStatus(originalRow);
                                        }
                                    }
                                }}
                            >
                                <span className="text-capitalize">{originalRow.status === 'rejected' ? 'Rejected' : 'Reject'}</span>
                            </button>
                            {originalRow?.rejected_comment && (
                                <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={(
                                        <Tooltip id="tooltip-top">
                                            {originalRow?.rejected_comment}
                                        </Tooltip>
                                    )}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-left-text comment-show" viewBox="0 0 16 16">
                                        <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                        <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                                    </svg>
                                </OverlayTrigger>
                            )}
                        </>
                    )}

                </div>
            ),
            id: 'ACTIONS',
        },
    ];

    useEffect(() => {
        const getSearchStatus = [];
        statusFilter.map((status) => {
            getSearchStatus.push(status.value);
        });
        if (getSearchStatus.length > 0) {
            dispatch(handleSearchParams({
                page: 1, keyword: '', status: getSearchStatus.toString(),
            }));
        } else {
            dispatch(handleSearchParams({ page: 1, keyword: '' }));
        }
        window.scrollTo(0, 0);
        addPageLoadActivityLog('Bitcoin Request');
    }, []);
    const [page, setPage] = useState(1);
    const handlePage = (value) => {
        setPage(value);
        const checkKeyWordExist = searchParams;
        const filterArr = { page: value };
        if (checkKeyWordExist?.status !== undefined && checkKeyWordExist?.keyword !== undefined) {
            filterArr.keyword = checkKeyWordExist?.keyword;
            filterArr.status = checkKeyWordExist?.status;
        } else if (checkKeyWordExist?.keyword !== undefined) {
            filterArr.keyword = checkKeyWordExist?.keyword;
        } else if (checkKeyWordExist?.status !== undefined) {
            filterArr.status = checkKeyWordExist?.status;
        }
        if (filterArr) {
            dispatch(handleSearchParams(filterArr));
        }
    };
    const handleDocumentClose = () => {
        setDocumentModal(false);
    };

    return (
        <>
            <DocumentPopup show={documentModal} handleClose={handleDocumentClose} documentInfo={selectedDocument} />
            <div>
                <div className="container-fluid">
                    <PageHeader
                        HeaderText="Payment"
                        Breadcrumb={[{ name: 'Payment', navigate: '' }, { name: 'Bitcoin Request', navigate: '/bitcoin-list' }]}
                    />
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className="card border-0 borderRadius-10 hidefilter">
                                <BasicTable
                                    data={data?.data || []}
                                    columns={columns}
                                    title="USDT TRC-20 Request List"
                                    isFetching={isFetching}
                                    isLoading={isLoading}
                                    deletePermission={deletePermission}
                                    editPermission={updatePermission}
                                    columnFilter={false}
                                    statusFilter={statusFilter}
                                />
                            </div>
                            <PaginationComponent
                                itemsCount={data.pagination?.total || 1}
                                itemsPerPage={10}
                                currentPage={searchParams?.page || page}
                                dataLength={data?.data?.length}
                                setCurrentPage={handlePage}
                                alwaysShown={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
BitcoinList.propTypes = {
    data: PropTypes.objectOf([object]),
    isLoading: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
};
BitcoinList.defaultProps = {
    data: [],
};
export default withGetContainer({ WrappedComponent: BitcoinList, url: 'platformadmin/payments?type=bitcoin&items_per_page=10', module: 'bitcoin' });
