/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHandleGetWithPostMutation } from '../../../app/features/api/CommonGetWithPostApi';
import { ADVERTISER, PLATFORM_PROVIDER } from '../../../Constant';
import SearchableSelect from '../CommonSearchableSelect';
import { handleReportData, handleSearchParams } from '../../../app/features/CommonSlice';
import { Toast } from '../Toaster';
import { useHandleGetApiQuery } from '../../../app/features/api/CommonGetApi';

const CommonGlobalChipFilter = ({
    filterOptions, setFilterOptions, statusOptions, advertiserSearch, componentName, setBlockedNumberFilter, initialFilter, handleSearch, search, nameSearch, searchData, initialSearchData, setSearch, setPage, setSearchData, handleResetBulkData,
}) => {
    console.log(advertiserSearch);
    const { user, userType } = useSelector((store) => store.auth);
    const [statusDataWithAll, setStatusDataWithAll] = useState([{ name: 'All', id: 'all' }]);
    const [yesNoOption, setYesNoOption] = useState([{ name: 'All', id: 'all' }, { name: 'Enable', id: 'yes' }, { name: 'Disable', id: 'no' }]);
    const { reportData, searchParams } = useSelector((store) => store.common);
    const [filterKycOption, setFilterKycOption] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState([]);
    const [campaignValues, setCampaignValues] = useState([]);
    const [targetValues, setTargetValues] = useState([]);
    const [selectedTermination, setSelectedTermination] = useState([]);
    const [campaignList, setCampaignList] = useState([]);
    const [terminationList, setTerminationList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectyesNoOption, setSelectedYesNo] = useState([]);
    const [handleGetWithPost] = useHandleGetWithPostMutation();
    const [selectedItems, setSelectedItems] = useState([]);
    const [campaignChips, setCampaignChips] = useState([]);
    const [targetChips, setTargetChips] = useState([]);
    const [searchElement, setSearchElement] = useState();
    // const [validationError, setValidationError] = useState([]);
    const [activeKey, setActiveKey] = useState(null); // No item is active by default
    // const [statusChips, setStatusChips] = useState([]);
    const filteredCampaignList = campaignList?.filter(
        (campaign) => !campaignChips.some((chip) => chip.id === campaign.id),
    );
    const filteredTargetList = terminationList?.filter(
        (campaign) => !targetChips.some((chip) => chip.id === campaign.id),
    );
    useEffect(() => {
        setStatusDataWithAll([...statusDataWithAll, ...statusOptions]);
    }, []);
    useEffect(() => {
        if (componentName === 'live-call' && userType === ADVERTISER && campaignChips.length === 0 && targetChips.length > 0) {
            setTargetChips([]);
        }
    }, [campaignChips, targetChips]);
    const { data: MarketingPlatform } = useHandleGetApiQuery(
        { url: 'marketing_platform?page=-1&number_of_records=10' },
        {
            skip: !filterKycOption.includes('Marketing Platform'),
            refetchOnMountOrArgChange: true,
        },
    );
    const [marketingList, setMarketingList] = useState([]);
    const [selectedMarketingPlatform, setSelectedMarketingPlatform] = useState([]);
    const [marketingChips, setMarketingChips] = useState([]);
    const { data: TransactionEvent } = useHandleGetApiQuery(
        { url: 'wallets/transactions/events' },
        {
            skip: !filterKycOption.includes('Transaction Event'),
            refetchOnMountOrArgChange: true,
        },
    );
    const [transactionEventList, setTransactionEventList] = useState([]);
    const [transactionEventChips, setTransactionEventChips] = useState([]);
    const [paymentMethodList, setPaymentMethodList] = useState(['IHA Wallet', 'Razorpay', 'Wire Transfer', 'USDT TRC-20']);
    const [paymentChips, setPaymentChips] = useState([]);
    const [countryIds, setCountryIds] = useState([]);
    const [stateIds, setStateIds] = useState([]);
    const [advertiserStatusList, setAdvertiserStatusList] = useState([
        { name: 'Active', id: 'active' },
        { name: 'Inactive', id: 'inactive' },
        { name: 'Pending', id: 'pending' },
        // { name: 'Suspended', id: 'suspended ' },
    ]);
    const [advertiserStatusChips, setAdvertiserStatusChips] = useState([]);
    const [currentPlanList, setCurrentList] = useState([]);
    const [currentPlanChips, setCurrentPlanChips] = useState([]);
    const [planStatusList, setPlanStatusList] = useState([
        { name: 'Active', id: 'active' },
        { name: 'Pending', id: 'pending' },
    ]);
    const [planStatusChips, setPlanStatusChips] = useState([]);
    const [selectedAdvertiserStatus, setSelectedAdvertiserStatus] = useState([]);
    const [selectedPlanStatus, setSelectedPlanStatus] = useState([]);
    const [selectedCurrentPlan, setSelectedCurrentPlan] = useState([]);

    const dispatch = useDispatch();

    const { data: CurrentPlan } = useHandleGetApiQuery(
        {
            url: 'platformadmin/plan/current_plan',
        },
        {
            skip: !filterKycOption.includes('Current Plan'),
            refetchOnMountOrArgChange: true,
        },
    );

    const { data: Countries } = useHandleGetApiQuery(
        {
            url: 'countries?page=-1',
        },
        {
            refetchOnMountOrArgChange: true,
        },
    );
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [countryChips, setCountryChips] = useState([]);
    const { data: States } = useHandleGetApiQuery(
        {
            url: `states/?country_id=${JSON.stringify(countryIds)}`,
        },
        {
            skip: countryIds.length === 0,
            refetchOnMountOrArgChange: true,
        },
        countryIds,
    );
    const [stateList, setStateList] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    const [stateChips, setStateChips] = useState([]);

    useEffect(() => {
        if (countryIds.length === 0 || !States) return;
        setStateList(States?.data);
    }, [countryIds, States]);

    const { data: Cities } = useHandleGetApiQuery(
        {
            url: `cities/?state_id=${JSON.stringify(stateIds)}`,
        },
        {
            skip: stateIds.length === 0,
            refetchOnMountOrArgChange: true,
        },
        stateIds,
    );
    const [cityList, setCityList] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [cityChips, setCityChips] = useState([]);
    useEffect(() => {
        if (stateIds.length === 0 || !Cities) return;
        setCityList(Cities.data);
    }, [stateIds, Cities]);
    useEffect(() => {
        setSelectedItems(initialFilter);
    }, [initialFilter]);
    // Fetch campaign list based on selected terminations
    const { data: allTargetCamp } = useHandleGetApiQuery(
        {
            url: 'dashboards/campaigns',
            params: {
                target_id: targetValues,
            },
        },
        {
            skip: targetValues.length === 0 && componentName !== 'cdr-reports',
            refetchOnMountOrArgChange: true,
        },
    );
    const terminationApi = (async (values, isRetech) => {
        const response = await handleGetWithPost({ url: 'dashboards/campaignsbyterminations', data: { campaigns_ids: values } });
        if (typeof isRetech === 'undefined' || !isRetech) {
            if (targetChips.length > 0) {
                // setTerminationList(response?.data?.data?.filter((option) => !(targetChips.map((item) => item.id).includes(option.id))));
            } else {
                setTerminationList(response?.data?.data);
            }
        }
    });
    const campaignDatabyAdvertiser = (async () => {
        const id = reportData?.advertiser_id ? reportData.advertiser_id : user?.id;
        const response = await handleGetWithPost({ url: 'dashboards/campaignsbyadvertisers', data: { advertiser_ids: [id] } });
        setCampaignList(response?.data?.data);
        if (campaignChips.length !== 0) {
            setCampaignList(filteredCampaignList);
        }
    });
    // Targets by advertiser
    const targetsDatabyAdvertiser = (async () => {
        const id = reportData?.advertiser_id ? reportData.advertiser_id : user?.id;
        const response = await handleGetWithPost({ url: 'dashboards/targetsbyadvertisers', data: { advertiser_ids: [id] } });
        setTerminationList(response?.data?.data);
        if (targetChips.length !== 0) {
            // setTerminationList(filteredCampaignList);
            const targetChipIds = new Set(targetChips.map((chip) => chip.id));
            const filteredTargets = response?.data?.data?.filter((target) => !targetChipIds.has(target.id));
            setTerminationList(filteredTargets);
        }
    });
    useEffect(() => {
        if (allTargetCamp?.data) {
            if (campaignChips.length !== 0) {
                setCampaignList(filteredCampaignList);
            } else {
                setCampaignList(allTargetCamp.data);
            }
        }
        if (targetValues?.length === 0) {
            campaignDatabyAdvertiser();
        }
    }, [targetValues, allTargetCamp]);
    // Admin campaigns and target first time
    useEffect(() => {
        campaignDatabyAdvertiser();
        if (userType === PLATFORM_PROVIDER) {
            targetsDatabyAdvertiser();
        }
    }, [reportData?.advertiser_id, user]);
    useEffect(() => {
        setFilterKycOption([...filterOptions]);
    }, []);
    useEffect(() => {
        if (TransactionEvent?.data) {
            setTransactionEventList(TransactionEvent.data);
        }
        if (MarketingPlatform?.data) {
            setMarketingList(MarketingPlatform.data);
        }
        if (Countries?.data) {
            setCountryList(Countries.data);
        }
        if (CurrentPlan?.data) {
            setCurrentList(CurrentPlan.data);
        }
    }, [TransactionEvent, MarketingPlatform, Countries, CurrentPlan]);

    const handleCampaignChange = (value) => {
        const campaigns = [...campaignList];
        const selectedId = parseInt(value, 10);
        const selectedItem = campaigns.find((item) => item.id === selectedId);
        setCampaignList((prevState) => prevState.filter((option) => option.id !== selectedId));
        if (selectedItem?.name) {
            setSelectedCampaign(selectedItem.name);
            setCampaignValues([...campaignValues, selectedItem.id]);
            if (!campaignChips.map((chip) => chip.id).includes(selectedItem.id)) {
                const updatedCampaignChips = [...campaignChips, selectedItem];
                setCampaignChips(updatedCampaignChips);
                dispatch(handleReportData({ ...reportData, campaign_id: updatedCampaignChips.map((item) => item.id) }));
                dispatch(handleSearchParams({ ...searchParams, campaign_id: updatedCampaignChips.map((item) => item.id) }));
            }
        }
    };
    const { data: allTargets } = useHandleGetApiQuery({ url: 'terminations?page=-1' });
    useEffect(() => {
        if (selectedItems.includes('Block Number Excluded')) {
            setBlockedNumberFilter(false);
            dispatch(handleReportData({ ...reportData, blacklisted_caller: false }));
            dispatch(handleSearchParams({ ...searchParams, blacklisted_caller: false }));
        }
    }, [selectedItems]);
    useEffect(() => {
        if (allTargets?.data) {
            if (targetChips?.length !== 0) {
                // setTerminationList(allTargets?.data?.data?.filter((option) => !(targetChips.map((item) => item.id).includes(option.id))));
                setTerminationList(filteredTargetList);
            } else {
                setTerminationList(allTargets?.data);
            }
        }
    }, [allTargets]);
    // add target from campaign
    useEffect(() => {
        if (campaignValues?.length > 0) {
            terminationApi(campaignValues, false);
        } else if (targetChips.length > 0) {
            const filteredTargets = allTargets?.data.filter((target) => !targetChips.some((chip) => chip.id === parseInt(target.id, 10)));
            setTerminationList(filteredTargets);
            if (userType === PLATFORM_PROVIDER) {
                targetsDatabyAdvertiser();
            }
        } else {
            setTerminationList(allTargets?.data);
            if (userType === PLATFORM_PROVIDER) {
                targetsDatabyAdvertiser();
            }
        }
    }, [campaignValues]);
    const handleTerminationChange = (async (value) => {
        const targets = [...terminationList];
        const selectedId = parseInt(value, 10);
        const selectedItem = targets.find((item) => parseInt(item.id, 10) === selectedId);
        setTerminationList((prevState) => prevState.filter((option) => parseInt(option.id, 10) !== selectedId));
        if (selectedItem?.name) {
            setSelectedTermination(selectedItem.name);
            setTargetValues([...targetValues, parseInt(selectedItem?.id, 10)]);
            if (!targetChips.map((chip) => chip.id).includes(selectedItem.id)) {
                const updatedTargetChips = [...targetChips, selectedItem];
                setTargetChips(updatedTargetChips);
                dispatch(handleReportData({ ...reportData, target_id: updatedTargetChips.map((item) => parseInt(item.id, 10)) }));
                if (componentName === 'live-call') {
                    dispatch(handleSearchParams({ ...searchParams, target_id: updatedTargetChips.map((item) => parseInt(item.id, 10)) }));
                }
            }
        }
    });
    const handleSearchData = () => {
        const searchP = { ...searchParams, page: 1 };
        setPage(1);
        dispatch(handleSearchParams({ ...searchP, ...searchElement, ...searchData }));
    };
    const handleSearchReset = () => {
        dispatch(handleSearchParams(initialSearchData));
        setSearch('');
        setSelectedStatus('');
        setSearchElement('');
        console.log(searchElement);
        setSelectedYesNo('');
        setSearchData('');
        setActiveKey(false);
        handleResetBulkData();
    };
    const handleStatusChange = (async (value) => {
        const statusKey = filterKycOption.includes('KYC Status') ? 'kyc_status' : 'status';
        if (value === 'all') {
            setSearchElement({ ...searchElement, [statusKey]: '' });
        } else {
            setSearchElement({ ...searchElement, [statusKey]: value });
        }
        setSelectedStatus(value);
    });
    useEffect(() => {
        if (selectedItems.includes('Publisher Status')) {
            setYesNoOption([{ name: 'All', id: '' }, { name: 'Registered', id: 'registered' }, { name: 'Pending', id: 'pending' }]);
        }
    }, [selectedItems]);
    const getFieldLabel = (type) => {
        if (nameSearch) return 'Name';
        if (type === 'First Name') return 'firstname';
        if (type === 'Last Name') return 'lastname';
        if (type === 'Publisher ID') return 'publisher_id';
        if (type === 'Email ID') return 'email';
        return '';
    };
    const handleValueSearch = (async (type, value) => {
        const key = getFieldLabel(type);
        setSearchElement({ ...searchElement, [key]: value });

        // if (value === 'all') {
        //     setSearchElement({ ...searchElement, [key]: '' });
        // } else {
        //     setSearchElement({ ...searchElement, [key]: value });
        // }
    });
    const handleRecordingChange = (async (value) => {
        const val = value === 'yes' ? 1 : 0;
        if (value === 'all') {
            setSearchElement({ ...searchElement, call_recording: '' });
        } else {
            setSearchElement({ ...searchElement, call_recording: val });
        }
        setSelectedYesNo(value);
    });
    const handleCurrentPlanChange = (async (value) => {
        const currentPlans = [...currentPlanList];
        const selectedId = parseInt(value, 10);
        const selectedItem = currentPlans.find((item) => item.id === selectedId);
        setCurrentList((prevState) => prevState.filter((option) => option.id !== selectedId));
        if (selectedItem?.name) {
            setSelectedCurrentPlan(selectedItem.name);
            if (!currentPlanChips.map((chip) => chip.id).includes(selectedItem.id)) {
                const updatedCurrentPlanChips = [...currentPlanChips, selectedItem];
                setCurrentPlanChips(updatedCurrentPlanChips);
                dispatch(handleSearchParams({ ...searchParams, current_plan: updatedCurrentPlanChips.map((item) => item.id) }));
            }
        }
    });
    const handlePlanStatusChange = (async (value) => {
        const planStatus = [...planStatusList];
        const selectedItem = planStatus.find((item) => item.id === value);
        setPlanStatusList((prevState) => prevState.filter((option) => option.id !== value));
        if (selectedItem?.name) {
            setSelectedPlanStatus(selectedItem.name);
            if (!planStatusChips.map((chip) => chip.id).includes(selectedItem.id)) {
                const updatedPlanStatusChips = [...planStatusChips, selectedItem];
                setPlanStatusChips(updatedPlanStatusChips);
                dispatch(handleSearchParams({ ...searchParams, plan_status: updatedPlanStatusChips.map((item) => item.id) }));
            }
        }
    });
    const handleAdvertiserStatusChange = (async (value) => {
        const advertiserStatus = [...advertiserStatusList];
        const selectedItem = advertiserStatus.find((item) => item.id === value);
        setAdvertiserStatusList((prevState) => prevState.filter((option) => option.id !== value));
        if (selectedItem?.name) {
            setSelectedAdvertiserStatus(selectedItem.name);
            if (!advertiserStatusChips.map((chip) => chip.id).includes(selectedItem.id)) {
                const updatedAdvertiserStatusChips = [...advertiserStatusChips, selectedItem];
                setAdvertiserStatusChips(updatedAdvertiserStatusChips);
                dispatch(handleSearchParams({ ...searchParams, advertiser_status: updatedAdvertiserStatusChips.map((item) => item.id) }));
            }
        }
    });
    const handleMarketingPlatform = (async (value) => {
        const marketing = [...marketingList];
        const selectedId = parseInt(value, 10);
        const selectedItem = marketing.find((item) => Number(item.id) === selectedId);
        setMarketingList((prevState) => prevState.filter((option) => Number(option.id) !== selectedId));
        if (selectedItem?.name) {
            setSelectedMarketingPlatform(selectedItem.name);
            if (!marketingChips.map((chip) => chip.id).includes(selectedItem.id)) {
                const updatedMarketingChips = [...marketingChips, selectedItem];
                setMarketingChips(updatedMarketingChips);
                dispatch(handleSearchParams({ ...searchParams, marketing_platform_ids: updatedMarketingChips.map((item) => item.id) }));
            }
        }
    });
    const handleTransactionChange = (async (value) => {
        const transactions = [...transactionEventList];
        const selectedItem = transactions.filter((item) => item === value);
        setTransactionEventList((prevState) => prevState.filter((option) => option !== value));
        if (selectedItem) {
            if (!transactionEventChips.map((chip) => chip).includes(...selectedItem)) {
                const updatedTransactionChips = [...transactionEventChips, ...selectedItem];
                setTransactionEventChips(updatedTransactionChips);
                dispatch(handleSearchParams({ ...searchParams, transaction_event: updatedTransactionChips.map((item) => item) }));
            }
        }
    });
    const handlePaymentChange = (async (value) => {
        const payments = [...paymentMethodList];
        const selectedItem = payments.filter((item) => item === value);
        setPaymentMethodList((prevState) => prevState.filter((option) => option !== value));
        if (selectedItem) {
            if (!paymentChips.map((chip) => chip).includes(...selectedItem)) {
                const updatedPaymentChips = [...paymentChips, ...selectedItem];
                setPaymentChips(updatedPaymentChips);
                dispatch(handleSearchParams({ ...searchParams, payment_method: updatedPaymentChips.map((item) => item) }));
            }
        }
    });
    const handleCountryChange = (async (value) => {
        const countries = [...countryList];
        const selectedId = parseInt(value, 10);
        const selectedItem = countries.find((item) => item.id === selectedId);
        setCountryList((prevCountry) => prevCountry.filter((option) => option.id !== selectedId));
        if (selectedItem?.name) {
            setSelectedCountry(selectedItem.name);
            if (!countryChips.map((chip) => chip.id).includes(selectedItem.id)) {
                const updatedCountryChips = [...countryChips, selectedItem];
                setCountryChips(updatedCountryChips);
                const ids = [];
                ids.push(selectedId);
                setCountryIds([...countryIds, ...ids]);
                dispatch(handleSearchParams({ ...searchParams, country_id: updatedCountryChips.map((item) => item.id) }));
            }
        }
    });
    const handleStateChange = (async (value) => {
        const states = [...stateList];
        const selectedId = parseInt(value, 10);
        const selectedItem = states.find((item) => item.id === selectedId);
        setStateList((prevState) => prevState.filter((option) => option.id !== selectedId));
        if (selectedItem?.name) {
            setSelectedState(selectedItem.name);
            if (!stateChips.map((chip) => chip.id).includes(selectedItem.id)) {
                const updatedStateChips = [...stateChips, selectedItem];
                setStateChips(updatedStateChips);
                const ids = [];
                ids.push(selectedId);
                setStateIds([...stateIds, ...ids]);
                dispatch(handleSearchParams({ ...searchParams, state_id: updatedStateChips.map((item) => item.id) }));
            }
        }
    });
    const handleCityChange = (async (value) => {
        const cities = [...cityList];
        const selectedId = parseInt(value, 10);
        const selectedItem = cities.find((item) => item.id === selectedId);
        setCityList((prevCity) => prevCity.filter((option) => option.id !== selectedId));
        if (selectedItem?.name) {
            setSelectedCity(selectedItem.name);
            if (!cityChips.map((chip) => chip.id).includes(selectedItem.id)) {
                const updatedCityChips = [...cityChips, selectedItem];
                setCityChips(updatedCityChips);
                dispatch(handleSearchParams({ ...searchParams, city_id: updatedCityChips.map((item) => item.id) }));
            }
        }
    });

    // const handleSelect = (eventKey) => {
    //     if (eventKey === 'Target' && componentName !== 'cdr-reports' && filterOptions.includes('Campaign') && campaignChips.length === 0) {
    //         Toast({ type: 'error', message: 'Please select campaign first.' });
    //     } else if (eventKey === 'Target' && componentName === 'live-call' && campaignChips.length === 0) {
    //         Toast({ type: 'error', message: 'Please select atleast one campaign first.' });
    //     } else if (eventKey === 'State' && filterOptions.includes('Country') && countryChips.length === 0) {
    //         Toast({ type: 'error', message: 'Please select country first.' });
    //     } else if (eventKey === 'City' && filterOptions.includes('State') && stateChips.length === 0) {
    //         Toast({ type: 'error', message: 'Please select state first.' });
    //     } else {
    //         setSelectedItems([...selectedItems, eventKey]);
    //         // remove used options from filter
    //         setFilterOptions(filterOptions.filter((option) => option !== eventKey));
    //     }
    // };

    const handleRemove = (item) => {
        const newSelectedItems = selectedItems.filter((selectedItem) => selectedItem !== item);
        setSelectedItems(newSelectedItems);
        setFilterOptions([...filterOptions, item]);
        // if (componentName === 'live-call' && userType === ADVERTISER && item === 'Campaign' && campaignChips.length === 0) {
        //     setSelectedItems([]);
        // }
    };
    const handleBlockNumberRemove = () => {
        dispatch(handleReportData({ ...reportData, blacklisted_caller: null }));
        dispatch(handleSearchParams({ ...searchParams, blacklisted_caller: null }));
        setBlockedNumberFilter(null);
        handleRemove('Block Number Excluded');
    };

    useEffect(() => {
        if (campaignChips?.length === 0 && userType === ADVERTISER && componentName === 'live-call') {
            setTerminationList([]);
            if (selectedItems.includes('Target')) Toast({ type: 'error', message: 'Please select atleast one campaign.' });
        }
    }, [campaignChips.length]);

    // const removeChip = (chipToRemove, action) => {
    //     if (action === 'campaign') {
    //         setCampaignList((prevCampaignList) => [...prevCampaignList, chipToRemove].sort((a, b) => a.id - b.id));
    //         const currentChips = campaignChips.filter((chip) => chip.id !== chipToRemove.id);
    //         setCampaignChips(currentChips);
    //         setCampaignValues(currentChips.map((item) => item.id));
    //         // handle report data used only for graph
    //         dispatch(handleReportData({ ...reportData, campaign_id: currentChips.map((item) => item.id) }));
    //         // handleSearchParams whole for search section
    //         dispatch(handleSearchParams({ ...searchParams, campaign_id: currentChips.map((item) => item.id) }));
    //         if (componentName === 'live-call') {
    //             dispatch(handleSearchParams({ ...searchParams, campaign_id: currentChips.map((item) => item.id), target_id: [] }));
    //         }
    //     }

    //     if (action === 'target') {
    //         setTerminationList((prevTargetList) => [...prevTargetList, chipToRemove].sort((a, b) => a.id - b.id));
    //         const currentChips = targetChips.filter((chip) => chip.id !== chipToRemove.id);
    //         setTargetChips(currentChips);
    //         setTargetValues((prev) => prev.filter((val) => val !== Number(chipToRemove.id)));
    //         dispatch(handleReportData({ ...reportData, target_id: currentChips.map((item) => parseInt(item.id, 10)) }));
    //         dispatch(handleSearchParams({ ...searchParams, target_id: currentChips.map((item) => item.id) }));
    //     }

    //     if (action === 'status') {
    //         const statusKey = filterKycOption.includes('KYC Status') ? 'kyc_status' : 'status';
    //         setStatusOptions((prevStatusOptions) => [...prevStatusOptions, chipToRemove].sort((a, b) => a.id - b.id));
    //         const currentChips = statusChips.filter((chip) => chip.id !== chipToRemove.id);
    //         setStatusChips(currentChips);
    //         dispatch(handleSearchParams({ ...searchParams, [statusKey]: currentChips.map((item) => item.id) }));
    //     }

    //     if (action === 'current_plan') {
    //         setCurrentList((prevCurrentPlanList) => [...prevCurrentPlanList, chipToRemove].sort((a, b) => a.id - b.id));
    //         const currentChips = currentPlanChips.filter((chip) => chip.id !== chipToRemove.id);
    //         setCurrentPlanChips(currentChips);
    //         dispatch(handleSearchParams({ ...searchParams, current_plan: currentChips.map((item) => item.id) }));
    //     }

    //     if (action === 'advertiser_status') {
    //         setAdvertiserStatusList((prevAdvertiserStatusList) => [...prevAdvertiserStatusList, chipToRemove].sort((a, b) => a.id - b.id));
    //         const currentChips = advertiserStatusChips.filter((chip) => chip.id !== chipToRemove.id);
    //         setAdvertiserStatusChips(currentChips);
    //         dispatch(handleSearchParams({ ...searchParams, advertiser_status: currentChips.map((item) => item.id) }));
    //     }

    //     if (action === 'plan_status') {
    //         setPlanStatusList((prevPlanStatusList) => [...prevPlanStatusList, chipToRemove].sort((a, b) => a.id - b.id));
    //         const currentChips = planStatusChips.filter((chip) => chip.id !== chipToRemove.id);
    //         setPlanStatusChips(currentChips);
    //         dispatch(handleSearchParams({ ...searchParams, plan_status: currentChips.map((item) => item.id) }));
    //     }
    //     if (action === 'marketing') {
    //         setMarketingList((prevMarketingList) => [...prevMarketingList, chipToRemove].sort((a, b) => a.id - b.id));
    //         const currentChips = marketingChips.filter((chip) => chip.id !== chipToRemove.id);
    //         setMarketingChips(currentChips);
    //         dispatch(handleSearchParams({ ...searchParams, marketing_platform_ids: currentChips.map((item) => item.id) }));
    //     }

    //     if (action === 'transaction') {
    //         setTransactionEventList((prevTransactionList) => [...prevTransactionList, chipToRemove].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())));
    //         const currentChips = transactionEventChips.filter((chip) => chip !== chipToRemove);
    //         setTransactionEventChips(currentChips);
    //         dispatch(handleSearchParams({ ...searchParams, transaction_event: currentChips.map((item) => item) }));
    //     }

    //     if (action === 'payment') {
    //         setPaymentMethodList((prevPaymentList) => [...prevPaymentList, chipToRemove].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())));
    //         const currentChips = paymentChips.filter((chip) => chip !== chipToRemove);
    //         setPaymentChips(currentChips);
    //         dispatch(handleSearchParams({ ...searchParams, payment_method: currentChips.map((item) => item) }));
    //     }

    //     if (action === 'country') {
    //         setCountryList((prevCountryList) => [...prevCountryList, chipToRemove].sort((a, b) => a.id - b.id));
    //         const currentChips = countryChips.filter((chip) => chip.id !== chipToRemove.id);
    //         setCountryChips(currentChips);
    //         setCountryIds(currentChips.map((item) => item.id));
    //         if (currentChips?.length === 0) {
    //             setStateList([]);
    //             setCityList([]);
    //             setStateChips([]);
    //             setCityChips([]);
    //             dispatch(handleSearchParams({
    //                 ...searchParams, country_id: currentChips.map((item) => item.id), state_id: [], city_id: [],
    //             }));
    //         } else {
    //             dispatch(handleSearchParams({ ...searchParams, country_id: currentChips.map((item) => item.id) }));
    //         }
    //     }

    //     if (action === 'state') {
    //         setStateList((prevStateList) => [...prevStateList, chipToRemove].sort((a, b) => a.id - b.id));
    //         const currentChips = stateChips.filter((chip) => chip.id !== chipToRemove.id);
    //         setStateChips(currentChips);
    //         setStateIds(currentChips.map((item) => item.id));
    //         if (currentChips?.length === 0) {
    //             setCityList([]);
    //             setCityChips([]);
    //             dispatch(handleSearchParams({ ...searchParams, state_id: currentChips.map((item) => item.id), city_id: [] }));
    //         } else {
    //             dispatch(handleSearchParams({ ...searchParams, state_id: currentChips.map((item) => item.id) }));
    //         }
    //     }

    //     if (action === 'city') {
    //         setCityList((prevCityList) => [...prevCityList, chipToRemove].sort((a, b) => a.id - b.id));
    //         const currentChips = cityChips.filter((chip) => chip.id !== chipToRemove.id);
    //         setCityChips(currentChips);
    //         dispatch(handleSearchParams({ ...searchParams, city_id: currentChips.map((item) => item.id) }));
    //     }
    // };
    useEffect(() => {
        if (userType === PLATFORM_PROVIDER && !reportData?.advertiser_id && componentName !== 'Transaction History') {
            setCampaignChips([]);
            setTargetChips([]);
            // setStatusChips([]);
            setMarketingChips([]);
            setTransactionEventChips([]);
            setPaymentChips([]);
            setCityChips([]);
            setStateChips([]);
            setCountryChips([]);
            setAdvertiserStatusChips([]);
            setPlanStatusChips([]);
            setCurrentPlanChips([]);
            setSelectedItems([]);
            setFilterOptions(initialFilter);
        }
    }, [reportData]);
    // const addFilter = () => (
    //     filterOptions.length > 0 ? filterOptions.map((option) => (
    //         <Dropdown.Item eventKey={option} key={option}>
    //             {option}
    //         </Dropdown.Item>
    //     )) : (
    //         <Dropdown.Item disabled key="no-data">
    //             No filter available
    //         </Dropdown.Item>
    //     ));

    // const renderDropdownContent = () => {
    //     if (userType === PLATFORM_PROVIDER && advertiserSearch && componentName !== 'Transaction History') {
    //         if (!reportData?.advertiser_id) {
    //             return (
    //                 <Dropdown.Item disabled key="no-data">
    //                     Please select advertiser first.
    //                 </Dropdown.Item>
    //             );
    //         }
    //         return addFilter();
    //     }
    //     return addFilter();
    // };

    return (
        <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
            <Accordion.Item>
                <Accordion.Header>
                    Search
                </Accordion.Header>
                <Accordion.Body>
                    <div className="col-md-12 pt-md-1 ps-0">
                        <div className="col-md-12 row mb-3 ps-0">
                            {nameSearch && (
                                <div className="col-md-4 flex-column">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        className="themeInput w-100"
                                        onChange={handleSearch}
                                        value={search}
                                    />
                                </div>
                            )}
                            {selectedItems.some((item) => ['Publisher ID', 'First Name', 'Last Name', 'Email ID'].includes(item))
                                && selectedItems.filter((i) => ['Publisher ID', 'First Name', 'Last Name', 'Email ID']?.includes(i)).map((item) => (
                                    <div className="col-md-4 flex-column" key={item}>
                                        <label className="mt-1">
                                            {item}
                                        </label>
                                        <input
                                            type={item === 'Email ID' ? 'email' : 'text'}
                                            placeholder={item}
                                            name={item}
                                            className="themeInput w-100"
                                            onChange={(e) => handleValueSearch(item, e.target.value)}
                                            value={searchElement ? searchElement?.[getFieldLabel(item)] : ''}
                                        />
                                        {/* {validationError.type === item && (
                                            <h4>
                                                {
                                                    validationError.message
                                                }
                                            </h4>
                                        )} */}
                                    </div>
                                ))}

                            {selectedItems.includes('Campaign') && (
                                <SearchableSelect
                                    label="Campaign"
                                    options={campaignList}
                                    onChange={handleCampaignChange}
                                    value={selectedCampaign}
                                    optionalName={'campaign_name'}
                                    name="campaign"
                                />
                            )}
                            {selectedItems.includes('Block Number Excluded') && (
                                <div className="filterSet-chart">
                                    <div className="delete-filterSelection">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleBlockNumberRemove()} width="20" height="20" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                        </svg>
                                    </div>
                                    <span>Block Number Excluded</span>
                                </div>
                            )}
                            {selectedItems.includes('Target') && (
                                <div className="filterSet-chart">
                                    <div className="delete-filterSelection">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove('Target')} width="20" height="20" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                        </svg>
                                    </div>
                                    <SearchableSelect
                                        label="target"
                                        options={terminationList}
                                        onChange={handleTerminationChange}
                                        value={selectedTermination}
                                        name="termination"
                                    />
                                </div>
                            )}
                            {(selectedItems.includes('Recording')) && (
                                <div className="col-md-4">
                                    <div className="input-group">
                                        {/* <label> Recording</label>
                                    <select
                                        name="recording"
                                        className="form-control"
                                        onChange={(e) => {
                                            handleRecordingChange(e);
                                        }}
                                        value={selectyesNoOption}
                                    >
                                        {yesNoOption.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select> */}

                                        <SearchableSelect
                                            label="Recording"
                                            options={yesNoOption}
                                            onChange={handleRecordingChange}
                                            value={selectyesNoOption}
                                            name="recording"
                                            displayLabel
                                            blankOption={false}
                                            withSearch={false}
                                        />
                                    </div>
                                </div>

                            )}
                            {(selectedItems.includes('Status') || selectedItems.includes('KYC Status')) && (
                                <div className="col-md-4">
                                    <div className="input-group">
                                        {/* <label>
                                        {' '}
                                        {selectedItems.includes('Status') ? 'Status' : 'KYC Status'}
                                    </label>
                                    <select
                                        name="status"
                                        className="form-control"
                                        onChange={(e) => {
                                            handleStatusChange(e);
                                        }}
                                        value={selectedStatus}
                                    >
                                        {statusDataWithAll.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select> */}
                                        <SearchableSelect
                                            label={selectedItems.includes('Status') ? 'Status' : 'KYC Status'}
                                            options={statusDataWithAll}
                                            onChange={handleStatusChange}
                                            value={selectedStatus}
                                            name="status"
                                            displayLabel
                                            blankOption={false}
                                            withSearch={false}
                                        />
                                    </div>
                                </div>
                            )}
                            {(selectedItems.includes('Publisher Status')) && (
                                <div className="col-md-4">
                                    <div className="input-group">
                                        {/* <label>
                                        {' '}
                                        {selectedItems.includes('Status') ? 'Status' : 'KYC Status'}
                                    </label>
                                    <select
                                        name="status"
                                        className="form-control"
                                        onChange={(e) => {
                                            handleStatusChange(e);
                                        }}
                                        value={selectedStatus}
                                    >
                                        {statusDataWithAll.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select> */}
                                        <SearchableSelect
                                            label="Publisher Status"
                                            options={yesNoOption}
                                            onChange={(value) => {
                                                setSearchElement({ ...searchElement, publisher_status: value });
                                                setSelectedYesNo(value);
                                            }}
                                            value={selectyesNoOption}
                                            name="publisher_status"
                                            displayLabel
                                            blankOption={false}
                                            withSearch={false}
                                        />
                                    </div>
                                </div>
                            )}
                            {selectedItems.includes('Marketing Platform') && (
                                <div className="filterSet-chart">
                                    <div className="delete-filterSelection">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove('Marketing Platform')} width="20" height="20" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                        </svg>
                                    </div>
                                    <SearchableSelect
                                        label="Marketing Platform"
                                        options={marketingList}
                                        onChange={handleMarketingPlatform}
                                        value={selectedMarketingPlatform}
                                        name="marketing_platform"
                                    />
                                </div>
                            )}
                            {selectedItems.includes('Transaction Event') && (
                                <div className="filterSet-chart">
                                    <div className="delete-filterSelection">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove('Transaction Event')} width="20" height="20" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                        </svg>
                                    </div>
                                    <SearchableSelect
                                        label="Transaction Event"
                                        options={transactionEventList}
                                        onChange={handleTransactionChange}
                                        value=""
                                        name="transaction_event"
                                        optionalFieldName=""
                                    />
                                </div>
                            )}
                            {selectedItems.includes('Payment Method') && (
                                <div className="filterSet-chart">
                                    <div className="delete-filterSelection">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove('Payment Method')} width="20" height="20" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                        </svg>
                                    </div>
                                    <SearchableSelect
                                        label="Payment Method"
                                        options={paymentMethodList}
                                        onChange={handlePaymentChange}
                                        value=""
                                        name="payment_method"
                                        optionalFieldName=""
                                    />
                                </div>
                            )}
                            {selectedItems.includes('City') && (
                                <div className="filterSet-chart">
                                    <div className="delete-filterSelection">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove('City')} width="20" height="20" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                        </svg>
                                    </div>
                                    <SearchableSelect
                                        label="city"
                                        options={cityList}
                                        onChange={handleCityChange}
                                        value={selectedCity}
                                        name="city_id"
                                    />
                                </div>
                            )}
                            {selectedItems.includes('State') && (
                                <div className="filterSet-chart">
                                    <div className="delete-filterSelection">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove('State')} width="20" height="20" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                        </svg>
                                    </div>
                                    <SearchableSelect
                                        label="state"
                                        options={stateList}
                                        onChange={handleStateChange}
                                        value={selectedState}
                                        name="state_id"
                                    />
                                </div>
                            )}
                            {selectedItems.includes('Country') && (
                                // <div className="filterSet-chart">
                                //     <div className="delete-filterSelection">
                                //         <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove('Country')} width="20" height="20" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                //             <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                //         </svg>
                                //     </div>
                                //     <
                                // </div>
                                <div className="col-md-4">
                                    <SearchableSelect
                                        label="Country"
                                        options={countryList}
                                        onChange={handleCountryChange}
                                        value={selectedCountry}
                                        name="country_id"
                                    />
                                </div>
                            )}
                            {selectedItems.includes('Advertiser Status') && (
                                <div className="filterSet-chart">
                                    <div className="delete-filterSelection">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove('Advertiser Status')} width="20" height="20" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                        </svg>
                                    </div>
                                    <SearchableSelect
                                        label="Advertiser Status"
                                        options={advertiserStatusList}
                                        onChange={handleAdvertiserStatusChange}
                                        value={selectedAdvertiserStatus}
                                        name="advertiser_status"
                                    />
                                </div>
                            )}
                            {selectedItems.includes('Plan Status') && (
                                <div className="filterSet-chart">
                                    <div className="delete-filterSelection">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove('Plan Status')} width="20" height="20" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                        </svg>
                                    </div>
                                    <SearchableSelect
                                        label="Plan Status"
                                        options={planStatusList}
                                        onChange={handlePlanStatusChange}
                                        value={selectedPlanStatus}
                                        name="plan_status"
                                    />
                                </div>
                            )}
                            {selectedItems.includes('Current Plan') && (
                                <div className="filterSet-chart">
                                    <div className="delete-filterSelection">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove('Current Plan')} width="20" height="20" fill="currentColor" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                        </svg>
                                    </div>
                                    <SearchableSelect
                                        label="Current Plan"
                                        options={currentPlanList}
                                        onChange={handleCurrentPlanChange}
                                        value={selectedCurrentPlan}
                                        name="current_plan"
                                    />
                                </div>
                            )}

                            {/* <div className="cursor-pointer chart-filter-selection">
                                <Dropdown onSelect={handleSelect}>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        +ADD FILTER
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-end">
                                        {renderDropdownContent()}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div> */}
                        </div>
                        <div className="col-md-4 ps-0">
                            <button className="btn btn-primary" onClick={handleSearchData}>Search</button>
                            <button className="btn btn-secondary ml-2" onClick={handleSearchReset}>Reset</button>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
CommonGlobalChipFilter.propTypes = {
    filterOptions: PropTypes.array,
    setFilterOptions: PropTypes.func,
    statusOptions: PropTypes.array,
    // setStatusOptions: PropTypes.func,
    advertiserSearch: PropTypes.bool,
    componentName: PropTypes.string,
    setBlockedNumberFilter: PropTypes.func,
    initialFilter: PropTypes.array,
    handleSearch: PropTypes.func,
    search: PropTypes.string,
    nameSearch: PropTypes.bool,
    searchData: PropTypes.instanceOf(Object),
    initialSearchData: PropTypes.instanceOf(Object),
    setSearch: PropTypes.func,
    setPage: PropTypes.func,
    setSearchData: PropTypes.func,
    handleResetBulkData: PropTypes.func,
};
CommonGlobalChipFilter.defaultProps = {
    filterOptions: [],
    statusOptions: [],
    advertiserSearch: false,
    componentName: '',
    initialFilter: [],
    nameSearch: false,
};
export default CommonGlobalChipFilter;
