/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes, { object } from 'prop-types';

import { useTable } from 'react-table';
import TableLoader from '../../screens/tableLoader';

const CommonTable = ({
    data, isLoading, isFetching, columns, initialState, width,
}) => {
    const tableInstance = useTable({
        columns,
        data,
        initialState,
    });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;
    return (
        <div className="body table-responsive">
            <table
                className="table table-hover"
                {...getTableProps()}
                style={{ width: `${width}` }}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map((column) => <th {...column.getHeaderProps()}>{column.render('Header')}</th>)
                            }
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {!isFetching && !isLoading ? rows.length > 0 ? rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
                            </tr>
                        );
                    }) : (
                        <tr>
                            <td className="text-center" colSpan={100}><p>No data available.</p></td>
                        </tr>
                    ) : (
                        <tr>
                            <td className="text-center" colSpan={100}><TableLoader /></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

CommonTable.propTypes = {
    data: PropTypes.instanceOf([object]).isRequired,
    columns: PropTypes.instanceOf([object]).isRequired,
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    initialState: PropTypes.instanceOf([object]),
    width: PropTypes.string,
};

CommonTable.defaultProps = {
    isLoading: false,
    isFetching: false,
    initialState: { hiddenColoumns: [] },
    width: '',
};

export default CommonTable;
