import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LoaderIcon from '../assets/images/loader-icon.png';

const TableLoader = ({ doNotScroll }) => {
    useEffect(() => {
        if (!doNotScroll) window.scrollTo(0, 0);
    }, []);

    return (

        <div className="main-table-loader">
            <div className="table-loader-icon-container">
                <img src={LoaderIcon} alt="Loader Icon" className="table-loader-icon" />
            </div>
        </div>

    );
};
export default TableLoader;

TableLoader.propTypes = {
    doNotScroll: PropTypes.bool.isRequired,
};
