/* eslint-disable linebreak-style */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardICon from '../assets/images/sidebar/dashboard.png';
import DashboardHoverICon from '../assets/images/sidebar/Dashboard-darkMode.png';
import campaignIcon from '../assets/images/sidebar/Campaign.png';
import campaignHoverIcon from '../assets/images/sidebar/Campaign-darkMode.png';
import targetICon from '../assets/images/sidebar/Target.png';
import targetHoverICon from '../assets/images/sidebar/Target-darkMode.png';
import numberIcon from '../assets/images/sidebar/Number.png';
import numberHoverIcon from '../assets/images/sidebar/Number-darkMode.png';
import userIcon from '../assets/images/sidebar/userManagement.png';
import userHoverIcon from '../assets/images/sidebar/UserManagement-darkmode.png';
import rolesICon from '../assets/images/sidebar/Roles.png';
import rolesHoverICon from '../assets/images/sidebar/Roles-darkMode.png';
import settingIcon from '../assets/images/sidebar/Settings.png';
import settingHoverIcon from '../assets/images/sidebar/Settings-darkMode.png';
import globalIcon from '../assets/images/sidebar/global.png';
import globalHoverIcon from '../assets/images/sidebar/Global-darkmode.png';
import reportIcon from '../assets/images/sidebar/Report.png';
import reportIconHover from '../assets/images/sidebar/Report-darkMode.png';
import expensesIcon from '../assets/images/sidebar/Expenses.png';
import expensesIconHover from '../assets/images/sidebar/Expenses-darkMode.png';
import advertiserIcon from '../assets/images/sidebar/Advertisers.png';
import advertiserHoverIcon from '../assets/images/sidebar/Advertisers-darkmode.png';
import planIcon from '../assets/images/sidebar/Plan.png';
import planHoverIcon from '../assets/images/sidebar/Plan-darkmode.png';
import didIcon from '../assets/images/sidebar/did.png';
import didIconHover from '../assets/images/sidebar/DID-darkmode.png';
import kycIcon from '../assets/images/sidebar/kyc.png';
import kycHoverIcon from '../assets/images/sidebar/KYC-darkmode.png';
import paymentIcon from '../assets/images/sidebar/payment.png';
import paymentHoverIcon from '../assets/images/sidebar/Payment-darkmode.png';
import TermsAndConditionIcon from '../assets/images/sidebar/terms.png';
import TermsAndConditionHoverIcon from '../assets/images/sidebar/Terms-darkmode.png';
import carrierIcon from '../assets/images/sidebar/sip.png';
import carrierHoverIcon from '../assets/images/sidebar/SIP-darkmode.png';
import notificationIcon from '../assets/images/sidebar/notification.png';
import providerIcon from '../assets/images/sidebar/Provider.png';
import notificationHoverIcon from '../assets/images/sidebar/Notification-darkmode.png';
import providerHoverIcon from '../assets/images/sidebar/Provider-darkmode.png';
import whiteListIcon from '../assets/images/sidebar/whiteList.png';
import whiteListIconHover from '../assets/images/sidebar/WhiteList-darkmode.png';
import activityIcon from '../assets/images/sidebar/activityLog.png';
import activityIconHover from '../assets/images/sidebar/ActivityLog-darkMode.png';
import helpHoverIcon from '../assets/images/sidebar/Help-darkmode.png';
import helpIcon from '../assets/images/sidebar/help.png';
import dynamicContentHoverIcon from '../assets/images/sidebar/dynamic-content-darkmode.png';
import dynamicContentIcon from '../assets/images/sidebar/dynamic-content.png';
import publisherIcon from '../assets/images/sidebar/Publisher.png';
import publisherHoverIcon from '../assets/images/sidebar/publisher-darkMode.png';

const getByPost = async ({ data, url }) => {
    const rawResponse = await fetch(process.env.REACT_APP_BASE_URL + url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    const content = await rawResponse.json();

    return content;
};
const convertToBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
        resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
        reject(error);
    };
});
const appendZero = (number) => {
    if (JSON.stringify(number)?.length < 2) {
        return `0${number}`;
    }
    return number;
};

const menusIcons = (menuSlug) => {
    const iconList = [
        {
            slug: 'dashboard',
            icon: { icon: DashboardICon, hoverIcon: DashboardHoverICon },
        },
        {
            slug: 'campaign',
            icon: { icon: campaignIcon, hoverIcon: campaignHoverIcon },
        },
        {
            slug: 'target_menu',
            icon: { icon: targetICon, hoverIcon: targetHoverICon },
        },
        {
            slug: 'number',
            icon: { icon: numberIcon, hoverIcon: numberHoverIcon },
        },
        {
            slug: 'settings',
            icon: { icon: settingIcon, hoverIcon: settingHoverIcon },
        },
        {
            slug: 'user',
            icon: { icon: userIcon, hoverIcon: userHoverIcon },
        },
        {
            slug: 'user_management',
            icon: { icon: userIcon, hoverIcon: userHoverIcon },
        },
        {
            slug: 'roles',
            icon: { icon: rolesICon, hoverIcon: rolesHoverICon },
        },
        {
            slug: 'reports',
            icon: { icon: reportIcon, hoverIcon: reportIconHover },
        },
        {
            slug: 'report',
            icon: { icon: reportIcon, hoverIcon: reportIconHover },
        },
        {
            slug: 'expenses',
            icon: { icon: expensesIcon, hoverIcon: expensesIconHover },
        },
        {
            slug: 'activity_log',
            icon: { icon: activityIcon, hoverIcon: activityIconHover },
        },
        {
            slug: 'plan',
            icon: { icon: planIcon, hoverIcon: planHoverIcon },
        },
        {
            slug: 'advertisers',
            icon: { icon: advertiserIcon, hoverIcon: advertiserHoverIcon },
        },
        {
            slug: 'kyc',
            icon: { icon: kycIcon, hoverIcon: kycHoverIcon },
        },
        {
            slug: 'payment',
            icon: { icon: paymentIcon, hoverIcon: paymentHoverIcon },
        },
        {
            slug: 'did_inventory',
            icon: { icon: didIcon, hoverIcon: didIconHover },
        },
        {
            slug: 'terms_condition',
            icon: { icon: TermsAndConditionIcon, hoverIcon: TermsAndConditionHoverIcon },
        },
        {
            slug: 'sip_trunk',
            icon: { icon: carrierIcon, hoverIcon: carrierHoverIcon },
        },
        {
            slug: 'notification',
            icon: { icon: notificationIcon, hoverIcon: notificationHoverIcon },
        },
        {
            slug: 'white_list_management',
            icon: { icon: whiteListIcon, hoverIcon: whiteListIconHover },
        },
        {
            slug: 'global_configuration',
            icon: { icon: globalIcon, hoverIcon: globalHoverIcon },
        },
        {
            slug: 'provider',
            icon: { icon: providerIcon, hoverIcon: providerHoverIcon },
        },
        {
            slug: 'help',
            icon: { icon: helpIcon, hoverIcon: helpHoverIcon },
        },
        {
            slug: 'dynamic_content',
            icon: { icon: dynamicContentIcon, hoverIcon: dynamicContentHoverIcon },
        },
        {
            slug: 'publisher',
            icon: { icon: publisherIcon, hoverIcon: publisherHoverIcon },
        },
    ];
    const menu = iconList.filter((item) => item.slug === menuSlug);
    return menu[0]?.icon;
};
const getModulePermission = (permission, type, childRoute, directDashboard) => {
    const { modulePermissionArr } = useSelector((store) => store.navigation);
    const navigate = useNavigate();
    const res = useLocation();
    let fullPath = res?.pathname;
    let baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split('/');
    fullPath = fullPath.split('/');
    fullPath = fullPath.length > 0 ? fullPath[baseUrl.length] : '/';
    fullPath = fullPath || '/';
    const activeKey1 = childRoute || fullPath;
    const getUrlInPermissionArr = modulePermissionArr?.permissions?.filter((item) => item.url === `/${activeKey1}`);
    if (getUrlInPermissionArr?.length > 0) {
        if (!getUrlInPermissionArr[0][permission]) {
            if (type === 'page') {
                navigate('/permission-denied');
            }
            if (type === 'button') {
                return false;
            }
        }
        if (type === 'button') {
            return true;
        }
    } else if (type === 'button' && !directDashboard) {
        return false;
    }
};
const fullName = (values) => {
    let name = '';
    const userType = values?.advertiser_user?.advertiser_role.type;
    if (values?.firstname) {
        name += userType === 'user' ? values?.advertiser_user?.firstname : values?.firstname;
    }
    if (values?.middlename) {
        name += userType === 'user' ? '' : ` ${values?.middlename}`;
    }
    if (values?.lastname) {
        name += userType === 'user' ? ` ${values?.advertiser_user?.lastname}` : ` ${values?.lastname}`;
    }
    return name;
};
const dateFormat = (dateObj) => {
    const year = dateObj.getFullYear();
    const month = `0${dateObj.getMonth() + 1}`.slice(-2);
    const day = `0${dateObj.getDate()}`.slice(-2);
    const withHyphens = [year, month, day].join('-');
    return withHyphens;
};
const addPageLoadActivityLog = async (moduleName, methodName = '') => {
    const body = methodName ? { method_name: methodName, module_name: moduleName } : { module_name: moduleName };
    await fetch(`${process.env.REACT_APP_BASE_URL}activity_logs`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: sessionStorage.getItem('token'),
        },
        body: JSON.stringify(body),
    });
};
const getGoogleAuthClientId = () => {
    const getCleintId = fetch(`${process.env.REACT_APP_BASE_URL}globals/configurations`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ slug: 'google_auth_token' }),
    });
    return getCleintId;
};

export {
    getByPost,
    convertToBase64,
    appendZero,
    menusIcons,
    getModulePermission,
    fullName,
    dateFormat,
    addPageLoadActivityLog,
    getGoogleAuthClientId,
};
