/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';
import PropTypes from 'prop-types';

const SearchableSelect = ({
    modalEl, options, onChange, value, label, name, optionalFieldName, isDisabled, multiple, multipleValue, optionalLabel, blankOption, required, dynamicCreateNewOption, allowClear, displayLabel, withSearch, showISO,
}) => {
    const select2Ref = useRef(null);
    const selectMultiRef = useRef(null);
    const selectPlaceholder = `Select ${label}${required ? '*' : ''}`;
    useEffect(() => {
        if (optionalLabel && !modalEl) {
            $(select2Ref.current).select2({
                placeholder: selectPlaceholder,
                allowClear: true,
                tags: dynamicCreateNewOption,
                minimumResultsForSearch: withSearch ? 0 : -1,

            });
        } else if (modalEl) {
            $(select2Ref.current).select2({
                placeholder: selectPlaceholder,
                allowClear,
                tags: !showISO,
                dropdownParent: $(select2Ref.current).closest(modalEl),
                minimumResultsForSearch: withSearch ? 0 : -1,

            });
        } else {
            $(select2Ref.current).select2({
                tags: dynamicCreateNewOption,
                minimumResultsForSearch: withSearch ? 0 : -1,
            });
        }
        $(selectMultiRef.current).select2({
            placeholder: selectPlaceholder,
            tags: dynamicCreateNewOption,
            tokenSeparators: [',', ' '],
            minimumResultsForSearch: withSearch ? 0 : -1,

        });

        const $select = $(select2Ref.current);
        const $selectMultiple = $(selectMultiRef.current);

        if (!multiple) {
            $(select2Ref.current).on('change', () => {
                // console.log$(this).val());
                // onChange($(this).val());
                onChange($select.val());
            });
        } else {
            $(selectMultiRef.current).on('change', (e) => {
                onChange(e);
            });
        }
        return () => {
            $(select2Ref.current).select2('destroy');
            $select.off('change');
            $(selectMultiRef.current).select2('destroy');
            $selectMultiple.off('change');
        };
    }, [onChange]);
    return (
        <>
            {multiple ? (
                <>
                    {/* <label htmlFor="exampleSelect" className="form-label">Recording</label> */}
                    <select ref={selectMultiRef} className="select2 form-control" value={multipleValue} onChange={onChange} disabled={isDisabled} name={name} multiple>
                        {options?.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option[optionalFieldName]}
                            </option>
                        ))}
                    </select>
                </>
            ) : (
                <>
                    {displayLabel && (<label htmlFor="exampleSelect" className="form-label">{label}</label>)}
                    <select ref={select2Ref} className="select2 form-control" value={value} onChange={onChange} disabled={isDisabled} name={name}>
                        {blankOption && (
                            <option value="">{optionalLabel ? '' : `${selectPlaceholder}`}</option>

                        )}
                        {options?.map((option) => {
                            const optionLabel = optionalFieldName ? option[optionalFieldName] : option;
                            const optionValue = optionalFieldName ? option.id : option;
                            const isoCode = option?.isoCode;
                            const countryId = option?.countryId;
                            return (
                                <option key={optionValue} value={showISO ? countryId : optionValue}>
                                    {showISO ? `${isoCode} ${'   '} ${optionLabel}` : optionLabel }
                                </option>
                            );
                        })}
                    </select>
                </>
            )}
        </>
    );
};
SearchableSelect.propTypes = {
    options: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
        PropTypes.number,
    ]),
    label: PropTypes.string,
    name: PropTypes.string,
    optionalFieldName: PropTypes.string,
    isDisabled: PropTypes.bool,
    multiple: PropTypes.bool,
    multipleValue: PropTypes.array,
    optionalLabel: PropTypes.bool,
    blankOption: PropTypes.bool,
    modalEl: PropTypes.string,
    dynamicCreateNewOption: PropTypes.bool,
    required: PropTypes.bool,
    allowClear: PropTypes.bool,
    displayLabel: PropTypes.bool,
    withSearch: PropTypes.bool,
    showISO: PropTypes.bool,

};
SearchableSelect.defaultProps = {
    optionalFieldName: 'name',
    isDisabled: false,
    multiple: false,
    value: '',
    optionalLabel: false,
    label: 'Carrier',
    blankOption: true,
    required: false,
    modalEl: '',
    dynamicCreateNewOption: false,
    allowClear: true,
    displayLabel: false,
    withSearch: true,
};
export default SearchableSelect;
