/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
// import BasicTable from '../../components/Tables/BasicTable';
import withGetContainer from '../../Container/withGetContainer';
import AlertIcon from '../../assets/images/alert-icon.svg';
import PaginationComponent from '../../components/Common/Pagination';
import { useHandleUpdateOrDeleteMutation } from '../../app/features/api/CommonChangeApi';
import { handleSearchParams } from '../../app/features/CommonSlice';
import CommonModdal from '../../components/Common/CommonModal';
import { addPageLoadActivityLog, getModulePermission } from '../../Util';
import BasicTableWithGlobalSearch from '../../components/Tables/BasicTableWIthGlobalSearch';
import { Toast } from '../../components/Common/Toaster';
import ExportCSVPopup from '../Reports/ExportCSVPopup';
import IsMoreDataPopup from '../Reports/IsMore';
import { useHandleGetWithPostMutation } from '../../app/features/api/CommonGetWithPostApi';
import { useHandlePutMutation } from '../../app/features/api/commonPutApi';

const PublisherList = ({
    data, isLoading, isFetching, handleDeleteMethod, refetchRecord,
}) => {
    getModulePermission('view', 'page');
    const MAX_LENGTH = 20;
    const editPermission = getModulePermission('update', 'button');
    // const [deletePopupClose, setDeletePopupClose] = useState(false);
    const deletePermission = getModulePermission('delete', 'button');
    const { searchParams } = useSelector((store) => store.common);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [handlePut, { isLoading: updateStatusLoading }] = useHandlePutMutation();
    const [handleUpdateOrDelete, { isLoading: updateActionLoading }] = useHandleUpdateOrDeleteMutation();
    const [updateData, setUpdateData] = useState({});
    const [resetIds, setResetIds] = useState(false);
    const filterArray = ['Publisher ID', 'First Name', 'Last Name', 'Email ID', 'Status', 'Publisher Status', 'Publisher Status'];
    const actions = [{ name: 'Enable Publisher', id: 'enable_publisher', url: '' }, { name: 'Disable Publisher', id: 'disable_publisher', url: '' }, { name: 'Delete Publisher', id: 'delete_publisher', url: '/delete_campaign' }];
    const [filterOptions, setFilterOptions] = useState(['Publisher ID', 'First Name', 'Last Name', 'Email ID', 'Status', 'Publisher Status']);
    const [actionToggle, setActionToggle] = useState(false);
    const [selectedAction, setSelectedAction] = useState({});
    const [actionCount, setActionCount] = useState('');
    const [toggleStatusModal, setToggleStatusModal] = useState(false);
    const [exportPopup, setExportPopup] = useState(false);
    const [isMoreData, setIsMoreData] = useState(false);
    const [count, setCount] = useState(0);
    const [resetAction, setResetAction] = useState(false);
    const [handleGetWithPost, { isLoading: exportLoading }] = useHandleGetWithPostMutation();
    const statusOptions = [
        { name: 'Enable', id: 1 },
        { name: 'Disable', id: 0 },
    ];
    const handleEdit = useCallback((value) => () => {
        navigate(`/publisher-list/edit-publisher/${value.id}`);
    }, []);
    const handleStatusModal = useCallback((value) => async () => {
        setUpdateData(value);
        setToggleStatusModal(true);
    }, []);
    const columns = [
        {
            Header: 'PUBLISHER ID',
            id: 'publisher_id',
            accessor: (originalRow) => (
                <p title={originalRow?.publisher_id}>
                    {originalRow?.publisher_id ? originalRow?.publisher_id : '' }
                </p>
            ),
        },
        {
            Header: 'FIRST NAME',
            id: 'name',
            accessor: (originalRow) => (
                <p title={originalRow?.firstname}>
                    {originalRow?.firstname ? (originalRow?.firstname.length >= MAX_LENGTH ? `${originalRow?.firstname?.substring(0, MAX_LENGTH)}...` : originalRow?.firstname) : ''}
                </p>

            ),
        },
        {
            Header: 'LAST NAME',
            id: 'last_name',
            accessor: (originalRow) => (
                <p title={originalRow?.lastname}>
                    {originalRow?.lastname ? (originalRow?.lastname.length >= MAX_LENGTH ? `${originalRow?.lastname?.substring(0, MAX_LENGTH)}...` : originalRow?.lastname) : ''}
                </p>

            ),
        },
        {
            Header: 'EMAIL ID',
            id: 'email',
            accessor: (originalRow) => (
                <p title={originalRow?.email}>
                    {originalRow?.email ? (originalRow?.email.length >= MAX_LENGTH ? `${originalRow?.email?.substring(0, MAX_LENGTH)}...` : originalRow?.email) : ''}
                </p>

            ),
        },
        {
            Header: 'STATUS',
            id: 'status',
            accessor: (row) => (
                <div>
                    <label id="slider" htmlFor={row?.id} className="switch">
                        <input
                            type="checkbox"
                            id={row?.id}
                            checked={row?.status}
                            name="status"
                            onChange={handleStatusModal(row)}
                        />
                        <span className="slider round" />
                    </label>
                </div>
            ),
        },
        {
            Header: 'PUBLISHER STATUS',
            id: 'publisher_status',
            accessor: (originalRow) => (
                <p title={originalRow?.publisher_status} className="text-capitalize">
                    {originalRow?.publisher_status }
                </p>

            ),
        },
        {
            Header: 'ACTIONS',
            accessor: (originalRow) => (
                <div className="d-flex align-items-center gap-2 action-lists">
                    {editPermission ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" onClick={handleEdit(originalRow)} title="Edit">
                            <title>Edit</title>
                            <path d="M16.2114 8.67142C15.8878 8.67142 15.6254 8.93377 15.6254 9.25737V17.8514C15.6254 18.3899 15.1873 18.828 14.6488 18.828H2.1485C1.61001 18.828 1.17191 18.3899 1.17191 17.8514V5.35101C1.17191 4.81252 1.61001 4.37442 2.1485 4.37442H10.7425C11.0661 4.37442 11.3284 4.11207 11.3284 3.78847C11.3284 3.46487 11.0661 3.20251 10.7425 3.20251H2.1485C0.963816 3.20251 0 4.16633 0 5.35101V17.8514C0 19.036 0.963816 19.9999 2.1485 19.9999H14.6488C15.8335 19.9999 16.7973 19.036 16.7973 17.8514V9.25737C16.7973 8.93377 16.535 8.67142 16.2114 8.67142Z" />
                            <path d="M19.6002 1.50465L18.4952 0.399735C17.9622 -0.133327 17.0948 -0.133327 16.5617 0.399735L7.72267 9.23881C7.64087 9.32061 7.58513 9.42479 7.56239 9.53824L7.00991 12.3005C6.97151 12.4926 7.03163 12.6912 7.17019 12.8297C7.28117 12.9407 7.43067 13.0013 7.5845 13.0013C7.62274 13.0013 7.66118 12.9976 7.69939 12.99L10.4616 12.4375C10.5751 12.4148 10.6792 12.359 10.761 12.2772L19.6002 3.43818C19.6002 3.43818 19.6002 3.43818 19.6002 3.43814C20.1332 2.90512 20.1332 2.03775 19.6002 1.50465ZM10.0579 11.3231L8.33147 11.6685L8.6768 9.94207L15.8711 2.74761L17.2523 4.12875L10.0579 11.3231ZM18.7715 2.60952L18.0809 3.30009L16.6998 1.91896L17.3903 1.22843C17.4665 1.15226 17.5904 1.15222 17.6666 1.22839L18.7715 2.33331C18.8477 2.40944 18.8477 2.53339 18.7715 2.60952Z" />
                        </svg>
                    ) : ''}
                    { deletePermission
                        ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" title="Delete" onClick={handleDeleteMethod(`publisher/${originalRow.id}`)}>
                                <title>Delete</title>
                                <path d="M14.8732 2.34369H12.1189V1.75775C12.1189 0.788493 11.3775 -6.10352e-05 10.4663 -6.10352e-05H6.05943C5.14821 -6.10352e-05 4.40686 0.788493 4.40686 1.75775V2.34369H1.65257C0.741344 2.34369 0 3.13224 0 4.1015C0 4.87994 0.478328 5.54138 1.13862 5.77185L2.12117 18.3881C2.19194 19.2919 2.91532 19.9999 3.76801 19.9999H12.7577C13.6104 19.9999 14.3338 19.2919 14.4046 18.3878L15.3871 5.77181C16.0474 5.54138 16.5257 4.87994 16.5257 4.1015C16.5257 3.13224 15.7844 2.34369 14.8732 2.34369ZM5.50858 1.75775C5.50858 1.43466 5.75569 1.17181 6.05943 1.17181H10.4663C10.77 1.17181 11.0172 1.43466 11.0172 1.75775V2.34369H5.50858V1.75775ZM13.3067 18.2908C13.2831 18.592 13.042 18.8281 12.7577 18.8281H3.76801C3.48381 18.8281 3.24268 18.592 3.21914 18.2911L2.25095 5.85931H14.2748L13.3067 18.2908ZM14.8732 4.68744H1.65257C1.34883 4.68744 1.10172 4.42458 1.10172 4.1015C1.10172 3.77841 1.34883 3.51556 1.65257 3.51556H14.8732C15.1769 3.51556 15.424 3.77841 15.424 4.1015C15.424 4.42458 15.1769 4.68744 14.8732 4.68744Z" />
                                <path d="M6.16797 17.1816L5.64454 8.19904C5.62664 7.89211 5.37689 7.65868 5.08973 7.67779C4.80117 7.69683 4.58178 7.96104 4.59965 8.26793L5.12309 17.2505C5.14029 17.5457 5.37074 17.7729 5.64503 17.7729C5.94817 17.7729 6.18657 17.5013 6.16797 17.1816Z" />
                                <path d="M8.26294 7.67676C7.97386 7.67676 7.7395 7.92604 7.7395 8.23353V17.2161C7.7395 17.5236 7.97386 17.7729 8.26294 17.7729C8.55202 17.7729 8.78638 17.5236 8.78638 17.2161V8.23353C8.78638 7.92604 8.55202 7.67676 8.26294 7.67676Z" />
                                <path d="M11.4357 7.67781C11.1478 7.65877 10.8988 7.89213 10.8809 8.19906L10.3575 17.1816C10.3396 17.4885 10.559 17.7527 10.8475 17.7718C11.1362 17.7908 11.3845 17.5573 11.4023 17.2505L11.9258 8.26795C11.9436 7.96102 11.7243 7.69682 11.4357 7.67781Z" />
                            </svg>
                        )
                        : ''}
                    {/* {(editPermission && originalRow.status !== 'live')
                            && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" style={{ pointerEvents: originalRow.show_icon ? 'auto' : 'none', opacity: originalRow.show_icon ? 1 : 0.3 }} onClick={handleUpdateStatus(originalRow)}>
                                    <title>Pause</title>
                                    <path d="M10.5257 19.9999C16.0485 19.9999 20.5257 15.5228 20.5257 9.9999C20.5257 4.47704 16.0485 -6.10352e-05 10.5257 -6.10352e-05C5.00288 -6.10352e-05 0.525635 4.47711 0.525635 9.99998C0.525635 15.5228 5.00281 19.9999 10.5257 19.9999ZM10.5257 1.59992C15.1573 1.59992 18.9257 5.36829 18.9257 9.9999C18.9257 14.6315 15.1573 18.3999 10.5257 18.3999C5.89406 18.3999 2.12569 14.6315 2.12569 9.9999C2.12569 5.36829 5.89362 1.59992 10.5257 1.59992Z" />
                                    <path d="M8.66726 14.7167C9.21964 14.7167 9.66729 14.2692 9.66729 13.7167V6.11673C9.66729 5.56435 9.21971 5.1167 8.66726 5.1167C8.11489 5.1167 7.66724 5.56428 7.66724 6.11673V13.7167C7.66724 14.2692 8.11481 14.7167 8.66726 14.7167Z" />
                                    <path d="M12.6672 14.7167C13.2196 14.7167 13.6672 14.2692 13.6672 13.7167V6.11673C13.6672 5.56435 13.2196 5.1167 12.6672 5.1167C12.1147 5.1167 11.6672 5.56435 11.6672 6.11673V13.7167C11.6672 14.2692 12.1148 14.7167 12.6672 14.7167Z" />
                                </svg>
                            )} */}
                    {/* {(editPermission && originalRow.status === 'live')
                                && (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="play-icon-svg" width="21" height="20" viewBox="0 0 14.92 14.918" onClick={handleUpdateStatus(originalRow)} style={{ pointerEvents: originalRow.show_icon ? 'auto' : 'none', opacity: originalRow.show_icon ? 1 : 0.3 }}>
                                        <title>Play</title>
                                        {' '}
                                        <g id="Group_289671" data-name="Group 289671" transform="translate(-1779 -529)">
                                            {' '}
                                            <path id="Path_49" data-name="Path 49" d="M1786.46,543.918a7.384,7.384,0,0,1-2.9-.586,7.513,7.513,0,0,1-2.38-1.6,7.48,7.48,0,0,1,0-10.549,7.492,7.492,0,0,1,2.38-1.6,7.448,7.448,0,0,1,8.17,1.6,7.273,7.273,0,0,1,1.6,2.372,7.438,7.438,0,0,1,0,5.807,7.265,7.265,0,0,1-1.6,2.37,7.428,7.428,0,0,1-5.27,2.185Zm0-13.719a6.26,6.26,0,1,0,6.26,6.26A6.268,6.268,0,0,0,1786.46,530.2Z" />
                                            {' '}
                                            <path id="Path_283711" data-name="Path 283711" d="M261.845,352.924a.872.872,0,0,0,0-1.451l-3.489-2.326a.872.872,0,0,0-1.356.726v4.652a.872.872,0,0,0,1.356.726Zm-.322-.484-3.489,2.326a.291.291,0,0,1-.452-.242v-4.652a.291.291,0,0,1,.452-.242l3.489,2.326a.291.291,0,0,1,0,.484Z" transform="translate(1527.388 184.141)" stroke="#444" strokeWidth="0.5" />
                                            {' '}
                                        </g>
                                        {' '}
                                    </svg>
                                )} */}
                </div>
                // <img
                //     src={originalRow.status === 'live' ? pauseIcon : playIcon}
                //     role="presentation"
                //     onClick={handleUpdateStatus(originalRow)}
                //     alt="Pause"
                //     width={15}
                //     title={originalRow.status === 'live' ? 'Pause' : 'Live'}
                // />
            ),
            id: 'ACTION',
        },
    ];

    useEffect(() => {
        // dispatch(handleSearchParams({
        //     page: 1, keyword: '',
        //     // status: [],
        // }));
        window.scrollTo(0, 0);
        addPageLoadActivityLog('Publisher');
    }, []);
    const [page, setPage] = useState(1);
    const handleNext = () => {
        navigate('/publisher-list/add-publisher');
    };
    const refetchRecordAccess = () => {
        dispatch(handleSearchParams({ ...searchParams, page: 1 }));
        setPage(1);
        refetchRecord();
    };
    const handleStatusChange = async () => {
        const datas = { status: !updateData.status };
        const res = await handlePut({
            url: `/publisher/status/${updateData.id}`,
            data: datas,
            method: 'PUT',
        });
        if (res?.data?.status === 200) {
            setToggleStatusModal(false);
            Toast({ type: 'success', message: res?.data?.message });
            setResetIds(true);
            setActionToggle('');
            setActionCount({});
            setSelectedAction({});
            refetchRecordAccess();
        }
    };
    const handleCsv = () => {
        navigate('/publisher-list/import-publisher');
    };
    const handleClose = () => {
        setToggleStatusModal(false);
        setResetAction(true);
    };
    const handlePage = (value) => {
        setPage(value);
        dispatch(handleSearchParams({ ...searchParams, page: value }));
    };
    const handlePerformAction = (async () => {
        const value = selectedAction;
        const obj = {
            action: value.action,
            publisher_ids: value.ids,
        };
        try {
            if (value.ids?.length > 0) {
                const res = await handleUpdateOrDelete({ url: '/publisher/bulk-action', method: 'PUT', data: obj });
                if (res?.data?.status === 200) {
                    Toast({ type: 'success', message: res?.data.message });
                    setActionToggle('');
                    setActionCount({});
                    setSelectedAction({});
                    setResetIds(true);
                    refetchRecord();
                } else if (res?.data?.status === 400) {
                    Toast({ type: 'error', message: res?.data.message });
                }
            }
        } catch (error) {
            Toast({ type: 'error', message: error?.data?.message });
        }
    });
    const shouldExportCSV = () => {
        setExportPopup(true);
    };

    const handleSelectedAction = (async (value) => {
        const { action } = value;
        setSelectedAction({ action, ids: value.ids });
        if (value.action === 'delete_publisher') {
            const checkCountUnAvaialble = data?.data.filter((item) => item.status === 'live');
            const unAvailableIds = checkCountUnAvaialble.map((item) => item.id);
            const matchingIds = unAvailableIds.filter((item) => value.ids.includes(item));
            console.log('parseInt(value.ids?.length, 10) - parseInt(matchingIds?.length, 10)', parseInt(value.ids?.length, 10) - parseInt(matchingIds?.length, 10));
            setActionCount({ unavailable: parseInt(matchingIds?.length, 10), available: parseInt(value.ids?.length, 10) - parseInt(matchingIds?.length, 10) });
            setActionToggle(true);
        } else if (value.action === 'enable_publisher') {
            const checkCountUnAvaialble = data?.data.filter((item) => item.status === true);
            const unAvailableIds = checkCountUnAvaialble.map((item) => item.id);
            const matchingIds = unAvailableIds.filter((item) => value.ids.includes(item));
            setActionCount({ unavailable: parseInt(matchingIds?.length, 10), available: parseInt(value.ids?.length, 10) - parseInt(matchingIds?.length, 10) });
            setActionToggle(true);
        } else if (value.action === 'disable_publisher') {
            const checkCountUnAvaialble = data?.data.filter((item) => item.status === false);
            const unAvailableIds = checkCountUnAvaialble.map((item) => item.id);
            const matchingIds = unAvailableIds.filter((item) => value.ids.includes(item));
            setActionCount({ unavailable: parseInt(matchingIds?.length, 10), available: parseInt(value.ids?.length, 10) - parseInt(matchingIds?.length, 10) });
            setActionToggle(true);
        }
    });
    const handleCloseAction = () => {
        setActionToggle(false);
        setResetIds(true);
    };
    const handleExportCsv = async () => {
        try {
            const values = {
                keyword: searchParams.keyword,
                advertiser_id: searchParams.advertiser_id,
                blacklisted_caller: searchParams?.blacklisted_caller,
                campaign_id: searchParams?.campaign_id,
                target_id: searchParams?.target_id,
                page: count,
            };

            const res = await handleGetWithPost({ url: 'report/export_csv', data: values });
            if (res.data.status === 200) {
                if (res.data.data.more_data) {
                    setIsMoreData(true);
                    setCount((prevCount) => prevCount + 1);
                } else {
                    setIsMoreData(false);
                }

                const downloadURL = res.data.data.url;
                const a = document.createElement('a');
                a.href = downloadURL;
                a.download = 'exported_data.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setExportPopup(false);
            }
        // eslint-disable-next-line no-empty
        } catch (error) {
        }
    };
    return (
        <div className="publisher-list-container">
            <ExportCSVPopup
                show={exportPopup}
                handleClose={handleClose}
                handleSubmit={handleExportCsv}
                loader={exportLoading}
            />
            {isMoreData && (
                <IsMoreDataPopup
                    show={isMoreData}
                    handleClose={handleClose}
                    handleSubmit={handleExportCsv}
                    loader={exportLoading}
                />
            )}
            <CommonModdal
                show={toggleStatusModal}
                type="closeOpt"
                classAddon="theme-cyan text-center footer-center"
                size="small"
                heading=""
                openSubmitModal={handleStatusChange}
                handleClose={handleClose}
                footerButtons1="Yes"
                footerButtons2="No"
                disabled={updateStatusLoading}
            >
                <img src={AlertIcon} className="mb-4" width="60" alt="clock" />
                <p>
                    Are you sure want to change the status?
                </p>
            </CommonModdal>
            <CommonModdal
                show={actionToggle}
                type="closeOpt"
                classAddon="theme-cyan text-center footer-center"
                size="small"
                heading=""
                openSubmitModal={handlePerformAction}
                handleClose={handleCloseAction}
                footerButtons1={actionCount?.available ? 'YES' : ''}
                footerButtons2={actionCount?.available ? 'NO' : 'OK'}
                disabled={updateActionLoading}
            >
                <img src={AlertIcon} className="mb-4" width="60" alt="clock" />
                {selectedAction?.action === 'delete_publisher' && (
                    <>
                        { actionCount?.available !== 0 && (
                            <p>
                                Are you sure you want to delete the publisher?
                            </p>
                        )}
                        <div className="weightage-p mt-2 d-flex flex-column gap-2">
                            <span>
                                <b>
                                    Selected
                                    {' '}
                                    {selectedAction?.ids?.length === 1 ? 'Publisher: ' : 'Publishers:'}
                                </b>
                                {' '}
                                {selectedAction?.ids?.length}
                            </span>
                            <span>
                                <b>
                                    Available for delete:
                                    {' '}
                                </b>
                                {actionCount?.available}
                            </span>
                            {actionCount?.unavailable !== 0 && (
                                <span>
                                    <b>
                                        Unavailable for delete:
                                        {' '}
                                    </b>
                                    {actionCount?.unavailable}
                                    {' '}
                                </span>
                            )}
                        </div>
                    </>
                )}
                {selectedAction?.action === 'enable_publisher' && (
                    <>
                        { actionCount?.available !== 0 && (
                            <p>
                                Are you sure you want to enable the publisher?
                            </p>
                        )}
                        <div className="weightage-p mt-2  d-flex flex-column gap-2">
                            <span>
                                <b>
                                    Selected
                                    {' '}
                                    {selectedAction?.ids?.length === 1 ? 'Publisher:' : 'Publishers:'}
                                </b>
                                {' '}
                                {selectedAction?.ids?.length}
                            </span>
                            <span>
                                <b>
                                    Available for enable publisher:
                                </b>
                                {' '}
                                {actionCount?.available}
                            </span>
                            {actionCount?.unavailable !== 0 && (
                                <span>
                                    <b>
                                        Unavailable for enable publisher:
                                        {' '}
                                    </b>
                                    {actionCount?.unavailable}
                                    {' '}
                                </span>
                            )}
                        </div>
                    </>
                )}
                {selectedAction?.action === 'disable_publisher' && (
                    <>
                        { actionCount?.available !== 0 && (
                            <p>
                                Are you sure you want to disable the publisher?
                            </p>
                        )}
                        <div className="weightage-p mt-2  d-flex flex-column gap-2">
                            <span>
                                <b>
                                    Selected
                                    {' '}
                                    {selectedAction?.ids?.length === 1 ? 'Publisher:' : 'Publishers:'}
                                    {' '}
                                </b>
                                {selectedAction?.ids?.length}
                            </span>
                            <span>
                                <b>
                                    Available for disable publisher:
                                </b>
                                {' '}
                                {actionCount?.available}
                            </span>
                            {actionCount?.unavailable !== 0 && (
                                <span>
                                    <b>
                                        Unavailable for disable publisher:
                                        {' '}
                                    </b>
                                    {actionCount?.unavailable}
                                    {' '}
                                </span>
                            )}
                        </div>
                    </>
                )}
            </CommonModdal>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Manage Publisher"
                    Breadcrumb={[
                        { name: 'Publishers', navigate: '' },
                    ]}
                />
                <div className="row clearfix">
                    <div className="col-lg-12 listing-data bulk-action-select">
                        <div className="">
                            <BasicTableWithGlobalSearch
                                data={data?.data || []}
                                setPage={setPage}
                                columns={columns}
                                handleExportCsv={shouldExportCSV}
                                // ExportCsv
                                btnText="Create Publisher"
                                handleClick={handleNext}
                                resetIdsToggle={resetIds}
                                setResetToggle={setResetIds}
                                title="Publisher list"
                                module={'publisher'}
                                showCommonChipsFilter
                                filterOptions={filterOptions}
                                setFilterOptions={setFilterOptions}
                                statusOptions={statusOptions}
                                isFetching={isFetching}
                                isLoading={isLoading}
                                handleImportCsv={handleCsv}
                                createButton
                                // importCsv
                                deletePermission={deletePermission}
                                editPermission={editPermission}
                                deletePopupToogle={false}
                                isRefetch
                                page={page}
                                resetAction={resetAction}
                                searching={false}
                                initialFilter={filterArray}
                                actions={actions}
                                handleSelectedAction={handleSelectedAction}
                                handleRefetchRecord={refetchRecordAccess}
                            />
                        </div>
                        <PaginationComponent
                            itemsCount={data.pagination?.total || 1}
                            itemsPerPage={10}
                            currentPage={page}
                            dataLength={data?.data?.length}
                            setCurrentPage={handlePage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
PublisherList.propTypes = {
    data: PropTypes.objectOf([object]),
    isLoading: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    handleDeleteMethod: PropTypes.func.isRequired,
    refetchRecord: PropTypes.func.isRequired,
};
PublisherList.defaultProps = {
    data: [],
};
export default withGetContainer({ WrappedComponent: PublisherList, url: 'publisher?items_per_page=10', module: 'publisher' });
