/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable valid-typeof */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PageHeader from '../../components/PageHeader';
import BasicTable from '../../components/Tables/BasicTable';
import withGetContainer from '../../Container/withGetContainer';
import PaginationComponent from '../../components/Common/Pagination';
import { handleSearchParams } from '../../app/features/CommonSlice';
import { addPageLoadActivityLog, getModulePermission } from '../../Util';
import { MAX_LENGTH, PLATFORM_PROVIDER } from '../../Constant';
import ExportCSVPopup from './ExportCSVPopup';
import IsMoreDataPopup from './IsMore';
import { useHandleGetWithPostMutation } from '../../app/features/api/CommonGetWithPostApi';
import Loader from '../loader';

const AdvertiserReport = ({
    data, isLoading, isFetching, refetchRecord,
}) => {
    getModulePermission('view', 'page');
    const dispatch = useDispatch();
    const { searchParams } = useSelector((store) => store.common);
    const [callSummaryPopup, setCallSummaryPopup] = useState(false);
    const [isMoreData, setIsMoreData] = useState(false);
    const [threshold, setThreshold] = useState(0);
    const [handleGetWithPost] = useHandleGetWithPostMutation();
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [exportLoader, setExportLoader] = useState(false);
    const filterArray = ['Current Plan', 'Plan Status'];
    const [filterOptions, setFilterOptions] = useState(filterArray);
    const date = new Date();
    const [datefilter, setDateFilter] = useState({ startDate: new Date(date.getFullYear(), date.getMonth(), 1), endDate: date });
    const datechange = (dateObject) => {
        if (!dateObject) {
            return '';
        }
        const year = dateObject.getFullYear();
        const month = `0${dateObject.getMonth() + 1}`.slice(-2);
        const day = `0${dateObject.getDate()}`.slice(-2);

        const withHyphens = [year, month, day].join('-');
        return withHyphens;
    };
    const startDate = datechange(datefilter.startDate);
    const endDate = datechange(datefilter.endDate);
    function convertSecondsToHHMMSS(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        const formattedDuration = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        return formattedDuration;
    }
    const refetchRecordAccess = () => {
        dispatch(handleSearchParams({ ...searchParams, page: 1 }));
        setPage(1);
        refetchRecord();
    };
    const defaultthreshold = (async () => {
        const response = await handleGetWithPost({ url: '/globals/configurations', data: { slug: 'threshold' } });
        if (response?.data?.status === 200) {
            const val = response?.data?.data[0]?.configuration_data?.threshold;
            setThreshold(val);
        }
    });
    useEffect(() => {
        defaultthreshold();
    }, []);
    const columns = [
        {
            Header: 'ADVERTISER NAME',
            accessor: (originalRow) => (
                <p title={originalRow?.name} className={'text-capitalize'}>
                    {originalRow?.name ? (
                        originalRow?.name.length >= MAX_LENGTH
                            ? `${originalRow?.name?.substring(0, MAX_LENGTH)}...`
                            : originalRow?.name
                    ) : (
                        '-'
                    )}
                </p>
            ),
        },
        {
            Header: 'CURRENT PLAN',
            accessor: 'current_plan',
        },
        {
            Header: 'PLAN STATUS',
            accessor: (row) => (
                <span className={`text-capitalize ${row.plan_status === 'pending' ? 'status-pending' : (row.plan_status === 'inactive' ? 'text-danger' : 'text-success')}`}>
                    {row.plan_status}
                </span>
            ),
        },
        {
            Header: 'TOTAL CALLS',
            accessor: 'total_calls',
        },
        {
            Header: 'CALL DURATION(HH:MM:SS)',
            accessor: 'call_duration',
        },
        {
            Header: 'CALL CHARGE',
            accessor: (row) => (
                <span className={'text-capitalize green-text'}>
                    {row?.call_charge > 0 ? `+ ${row?.currency_symbol ? row?.currency_symbol : ''}${row?.call_charge}` : `${row?.currency_symbol ? row?.currency_symbol : ''}${row?.call_charge}`}
                </span>
            ),
        },
        {
            Header: 'REVENUE',
            accessor: (row) => (
                <span className={'text-capitalize green-text'}>
                    {row.revenue > 0 ? `+ ${row.currency_symbol ? row.currency_symbol : ''}${row.revenue}` : `${row.currency_symbol ? row.currency_symbol : ''}${row.revenue}`}
                </span>
            ),
        },
        {
            Header: 'EXPENSE',
            accessor: (row) => (
                <span className={'text-capitalize text-red'}>
                    {row?.expense > 0 ? `- ${row?.currency_symbol ? row?.currency_symbol : ''}${row?.expense}` : `${row?.currency_symbol ? row?.currency_symbol : ''}${row?.expense}`}
                </span>
            ),
        },
        {
            Header: 'PROFIT/ LOSS',
            accessor: (row) => {
                const mainProfitValue = row?.profit;
                const profiltVal = parseFloat(row?.profit) < parseFloat(0) ? row?.profit?.replace('-', '') : '';
                return (
                    <span className={`text-capitalize ${parseFloat(mainProfitValue) < parseFloat(0) ? 'text-red' : 'green-text'}`}>
                        {!profiltVal ? `${mainProfitValue < 0 ? '+' : ''} ${row?.currency_symbol ? row?.currency_symbol : ''}${mainProfitValue}` : `- ${row?.currency_symbol ? row?.currency_symbol : ''}${profiltVal}`}
                    </span>
                );
            },
        },
        {
            Header: 'WALLET BALANCE',
            accessor: (row) => (
                <>
                    <span className={`text-capitalize ${parseFloat(row?.wallet_balance) <= parseFloat(threshold) ? 'text-red' : ''}`}>
                        {`${row?.currency_symbol ? row?.currency_symbol : ''}${parseFloat(row?.wallet_balance) === 0 ? 0 : row?.wallet_balance}`}
                    </span>
                    { parseFloat(row?.wallet_balance) <= parseFloat(threshold) && (
                        <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={(
                                <Tooltip id="tooltip-top">
                                    Low Balance
                                </Tooltip>
                            )}
                        >
                            <i className="icon-question ml-1 align-middle" />
                        </OverlayTrigger>
                    )}
                </>

            ),
        },
    ].filter(Boolean);
    useEffect(() => {
        if (datefilter) {
            dispatch(handleSearchParams({
                page: 1, keyword: '', startDate, endDate,
            }));
        } else {
            dispatch(handleSearchParams({ page: 1, keyword: '' }));
        }
        window.scrollTo(0, 0);
        addPageLoadActivityLog('Advertiser Report');
    }, []);
    const clearedCalendar = () => {
        dispatch(handleSearchParams({
            page: 1, keyword: '',
        }));
    };
    useEffect(() => {
        const closeIcon = document.querySelector('.react-datepicker__close-icon');
        if (closeIcon) {
            closeIcon.addEventListener('click', clearedCalendar);
        }
        return () => {
            if (closeIcon) {
                closeIcon.removeEventListener('click', clearedCalendar);
            }
        };
    }, []);
    const handlePage = (value) => {
        setPage(value);
        dispatch(handleSearchParams({ ...searchParams, page: value }));
    };
    const shouldExportCSV = () => {
        setCallSummaryPopup(true);
    };
    const openPopup = () => {
        setIsMoreData(true);
    };

    const handleExportCsv = async () => {
        try {
            setExportLoader(true);
            const values = {
                startDate: datechange(datefilter.startDate),
                endDate: datechange(datefilter.endDate),
                page: count,
                keyword: searchParams.keyword,
                advertiser_id: searchParams.advertiser_id,
            };

            const res = await handleGetWithPost({ url: 'platformadmin/advertiser/export_csv', data: values });

            if (res.data.status === 200) {
                setExportLoader(false);
                if (res.data.data.more_data) {
                    setIsMoreData(true);
                    setCount((prevCount) => prevCount + 1);
                } else {
                    setIsMoreData(false);
                }

                const downloadURL = res.data.data.url;
                const a = document.createElement('a');
                a.href = downloadURL;
                a.download = 'exported_data.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setCallSummaryPopup(false);
            } else {
                setExportLoader(false);
            }
        // eslint-disable-next-line no-empty
        } catch (error) {
        }
    };
    const handleClose = () => {
        setCallSummaryPopup(false);
        setIsMoreData(false);
        setCount(0);
    };
    useEffect(() => {
        if (isMoreData) {
            openPopup();
        }
    }, [isMoreData]);
    return (
        <div>
            <ExportCSVPopup
                show={callSummaryPopup}
                handleClose={handleClose}
                handleSubmit={handleExportCsv}
                loader={exportLoader}
            />
            {isMoreData && (
                <IsMoreDataPopup
                    show={isMoreData}
                    handleClose={handleClose}
                    handleSubmit={handleExportCsv}
                    loader={exportLoader}
                />
            )}
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Reports"
                    Breadcrumb={[
                        { name: 'Reports', navigate: '' },
                        { name: 'Advertiser', navigate: '' },
                    ]}
                />
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="card border-0 borderRadius-10 ar-data-show">
                            <BasicTable
                                data={data?.data || []}
                                columns={columns}
                                title="Advertiser Report"
                                showCommonChipsFilter
                                filterOptions={filterOptions}
                                setFilterOptions={setFilterOptions}
                                isFetching={isFetching}
                                isLoading={isLoading}
                                handleExportCsv={shouldExportCSV}
                                ExportCsv
                                showCalander
                                // advertiserSearch
                                columnFilter={false}
                                datepickerRange={datefilter}
                                datechangeFilter={setDateFilter}
                                isRefetch
                                handleRefetchRecord={refetchRecordAccess}
                                initialFilter={filterArray}
                            />
                        </div>
                        <PaginationComponent
                            itemsCount={data.pagination?.total || 1}
                            itemsPerPage={10}
                            currentPage={page}
                            dataLength={data?.data?.length}
                            setCurrentPage={handlePage}
                            alwaysShown={false}

                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
AdvertiserReport.propTypes = {
    data: PropTypes.objectOf([object]),
    isLoading: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    refetchRecord: PropTypes.func.isRequired,
};
AdvertiserReport.defaultProps = {
    data: [],
};
export default withGetContainer({ WrappedComponent: AdvertiserReport, url: 'platformadmin/advertiser/?items_per_page=10', module: '' });
