/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
/* eslint-disable array-callback-return */
/* eslint-disable no-import-assign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
    useCallback, useEffect, useState,
} from 'react';
import ReactEcharts from 'echarts-for-react';
import PropTypes, { object } from 'prop-types';
import { useTable } from 'react-table';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
// import Loader from '../../screens/loader';
import TableLoader from '../../screens/tableLoader';
import Reportchart from '../../screens/reportChart';
import { handleReportData, handleSearchParams } from '../../app/features/CommonSlice';
import { getModulePermission } from '../../Util';
import { ADVERTISER, PLATFORM_PROVIDER } from '../../Constant';
import 'react-datepicker/dist/react-datepicker.css';
import SearchableAdvertiserSelect from '../Common/CommonSerchableWithapi';
import { Toast } from '../Common/Toaster';
import filterIcon from '../../assets/images/filterIcon.png';
import { useHandleGetApiQuery } from '../../app/features/api/CommonGetApi';
import SearchableSelect from '../Common/CommonSearchableSelect';
import CommonModdal from '../Common/CommonModal';
import AlertIcon from '../../assets/images/alert-icon.svg';
// import CommonChipsFilter from '../Common/CommonChipsFilter/CommonChipsFilter';
import FilterTable from '../Common/filterTable';
import CommonGlobalChipFilter from '../Common/CommonChipsFilter/CommonGlobalChipFilter';

const BasicTableWithGlobalSearch = ({
    title, handleClick, btnText, data, isLoading, isFetching, liveCall, module,
    columns, createButton, deletePermission, editPermission, showPagination, handleFilterTablePage, currentFilterTablePage,
    importCsv, handleImportCsv, columnFilter, statusFilter, componentName, searching, handleExportCsv, ExportCsv, handleExportPdf, ExportPdf, showChart, showCommonChipsFilter, filterOptions, setFilterOptions, statusOptions, setStatusOptions, refetchDetailRecord,
    showfilterTable, setIdObjectForAdvertiser, idObjectForAdvertiser,
    individualColumns, individualData, dataLoader, timeInterval, setTimeInterval,
    isLiveCallTableOpen, setIsLiveCallTableOpen,
    showCalander, showTimeZone, datepickerRange, advertiserSearch, datechangeFilter, isRefetch, handleRefetchRecord, notRequiredMaxDateInDatePicker, graphshow, graphOption, initialFilter, graphTitle,
    actions, handleSelectedAction, setPage, resetIdsToggle, page, setResetToggle, resetAction,
}) => {
    const [search, setSearch] = useState('');
    const { searchParams, reportData } = useSelector((store) => store.common);
    const [searchData, setSearchData] = useState('');
    const { userType, user } = useSelector((store) => store.auth);
    const createPermission = getModulePermission('create', 'button');
    const dispatch = useDispatch();
    const [tableColumn, setTableColumn] = useState(columns);
    const [allChecked, setAllChecked] = useState(true);
    const allStatusFilter = statusFilter;
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [liveCallData, setLiveCallData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [statusFilterData, setStatusFilterData] = useState();
    const [advertiserId, setAvertiserId] = useState('');
    const [blackNumberExcluded, setBlackNumberExcluded] = useState(null);
    const [initialSearchData, setInitialSearchData] = useState();
    const [timeZone, setTimeZone] = useState('');
    const [selectAction, setSelectedAction] = useState();
    const [deletePopup, setDeletePopup] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedIdsPageWise, setSelectedIdsPageWise] = useState([]);
    const [selectAllPages, setSelectAllPages] = useState([]);
    const datechange = (dateObject) => {
        if (dateObject) {
            const year = dateObject.getFullYear();
            const month = `0${dateObject.getMonth() + 1}`.slice(-2);
            const day = `0${dateObject.getDate()}`.slice(-2);

            const withHyphens = [year, month, day].join('-');
            return withHyphens;
        }
        return null;
    };
    const currentDate = new Date();
    const handleAdvertiserChange = (val) => {
        setAvertiserId(val);
    };
    useEffect(() => {
        if (liveCall) {
            setLiveCallData(data);
        }
    }, [data]);
    useEffect(() => {
        if (liveCall) {
            if (advertiserId) {
                const newFilteredData = data.filter((item) => item.advertiser_id === advertiserId);
                setLiveCallData(newFilteredData);
                setFilteredData(newFilteredData);
            } else {
                setLiveCallData(data);
            }
        }
    }, [data, advertiserId]);
    useEffect(() => {
        if (!liveCall) {
            const filterData = allStatusFilter?.length > 0 ? allStatusFilter?.map((item) => item.value) : [];
            setStatusFilterData(filterData.toString());
        }
    }, [statusFilter]);
    useEffect(() => {
        if (advertiserId) {
            dispatch(handleSearchParams({
                ...searchParams, advertiser_id: advertiserId, page: 1,
            }));
            dispatch(handleReportData({ ...reportData, advertiser_id: advertiserId }));
        }
    }, [advertiserId]);
    useEffect(() => {
        dispatch(handleReportData({
            ...reportData,
            timezone_id: timeZone,
        }));
        if (advertiserId) {
            dispatch(handleSearchParams({
                ...searchParams,
                timezone_id: timeZone,
                advertiser_id: advertiserId,
            }));
        } else if (timeZone) {
            const searchP = {
                page: 1,
                // keyword: '',
                // timezone_id: timeZone,
            };
            dispatch(handleSearchParams(searchP));
        }
    }, [timeZone]);

    useEffect(() => {
        setStartDate(datepickerRange?.startDate);
        setEndDate(datepickerRange?.endDate);
        dispatch(handleReportData({
            ...reportData,
            timezone_id: timeZone,
            startDate: datechange(datepickerRange?.startDate),
            endDate: datechange(datepickerRange?.endDate),
        }));
    }, [datepickerRange]);
    const setBlockedNumberFilter = (val) => {
        setBlackNumberExcluded(val);
        setIdObjectForAdvertiser({ ...idObjectForAdvertiser, blacklisted_caller: val });
        setTimeout(() => {
            refetchDetailRecord();
        }, 100);
    };
    const handleDateRangeChange = (dates) => {
        datechangeFilter({ startDate: dates[0], endDate: dates[1] });
        setStartDate(dates[0]);
        setEndDate(dates[1]);
        if (dates[0] && dates[1]) {
            if (statusFilterData) {
                dispatch(handleReportData({
                    ...reportData,
                    startDate: datechange(dates[0]),
                    endDate: datechange(dates[1]),
                    timezone_id: timeZone,
                }));

                dispatch(handleSearchParams({
                    keyword: search,
                    page: 1,
                    startDate: datechange(dates[0]),
                    endDate: datechange(dates[1]),
                    status: statusFilterData,
                    timezone_id: timeZone,
                }));
            } else {
                dispatch(handleReportData({
                    ...reportData,
                    startDate: datechange(dates[0]),
                    endDate: datechange(dates[1]),
                    timezone_id: timeZone,
                    advertiser_id: advertiserId,
                }));
                dispatch(handleSearchParams({
                    keyword: search,
                    page: 1,
                    startDate: datechange(dates[0]),
                    endDate: datechange(dates[1]),
                    timezone_id: timeZone,
                    advertiser_id: advertiserId,
                }));
            }
        } else if (dates[0] === null && dates[1] === null) {
            Toast({ type: 'error', message: 'Date range selection mandatory.' });
            // dispatch(handleReportData({
            //     ...reportData, startDate: '', endDate: '', timezone_id: timeZone, advertiser_id: advertiserId,
            // }));
            // dispatch(handleSearchParams({
            //     ...searchParams, startDate: '', endDate: '', timezone_id: timeZone, advertiser_id: advertiserId,
            // }));
        }
    };
    useEffect(() => {
        if (timeInterval === 1) {
            setStartDate(new Date());
            setEndDate(new Date());
            dispatch(handleSearchParams({
                ...searchParams,
                // startDate: datechange(new Date()),
                // endDate: datechange(new Date()),
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            }));
            setTimeInterval(1);
        }
    }, [timeInterval]);
    const handleActionChange = (value) => {
        setSelectedAction(value);
        setResetToggle(false);
        if (!selectedIds || selectedIds.length === 0) {
            console.log('No items selected for bulk action.');
            // return;
        }
        if (selectedIds?.length > 0) {
            if (value === 'delete') {
                setDeletePopup(true);
            } else {
                handleSelectedAction({ action: value, ids: selectedIds });
                setDeletePopup(false);
            }
        }
    };
    useEffect(() => {
        if (selectedIds?.length === 0) {
            setSelectedAction('');
        }
    }, [selectedIds]);
    const handleResetData = () => {
        setSelectedIds([]);
        setSelectedAction('');
        setSelectedIdsPageWise({});
        setSelectAllPages([]);
    };
    useEffect(() => {
        if (resetIdsToggle) {
            handleResetData();
        }
    }, [resetIdsToggle]);
    useEffect(() => {
        if (resetAction) {
            setSelectedAction('');
        }
    }, [resetAction]);
    const handleSelectedIdsforAction = (event, rowId) => {
        const isChecked = event.target.checked;
        if (rowId === 'all') {
            if (isChecked) {
                const allIds = data?.map((item) => item.id) || [];
                setSelectedIds((prevSelectedIds) => [...new Set([...prevSelectedIds, ...allIds])]);
                setSelectAllPages((prevPages) => [...new Set([...prevPages, page])]);
                setSelectedIdsPageWise((prevData) => ({
                    ...prevData,
                    [page]: [...new Set([...(prevData[page] || []), ...allIds])], // Default to an empty array if undefined
                }));
            } else {
                const allIds = data?.map((item) => item.id) || [];
                setSelectedIds((prevSelectedIds) => prevSelectedIds.filter((id) => !allIds.includes(id)));
                setSelectAllPages((prevPages) => prevPages.filter((item) => item !== page));
                setSelectedIdsPageWise((prevData) => ({
                    ...prevData,
                    [page]: (prevData[page] || []).filter((id) => !allIds.includes(id)), // Default to an empty array if undefined
                }));
            }
        } else {
            setSelectedIds((prevSelectedIds) => {
                if (isChecked) {
                    return [...prevSelectedIds, rowId];
                }
                return prevSelectedIds.filter((id) => id !== rowId);
            });
            setSelectedIdsPageWise((prevData) => {
                if (isChecked) {
                    // Add the rowId to the array for the specific page
                    return {
                        ...prevData, // Preserve existing data
                        [page]: [...(prevData[page] || []), rowId], // Add rowId to the specified page
                    };
                }
                // Remove the rowId from the array for the specific page
                return {
                    ...prevData,
                    [page]: (prevData[page] || []).filter((id) => !rowId.includes(id)), // Remove rowId from the specified page
                };
            });
            let selectCheckbox = [];
            const pageWiseData = selectedIdsPageWise;
            if (isChecked) {
                selectCheckbox = {
                    ...pageWiseData, // Preserve existing data
                    [page]: [...(pageWiseData?.[page] || []), rowId], // Add rowId to the specified page
                };
            } else {
                selectCheckbox = {
                    ...pageWiseData,
                    [page]: (pageWiseData?.[page] || []).filter((id) => !rowId.includes(id)), // Remove rowId from the specified page
                };
            }
            const selectedIdsCount = selectCheckbox?.[page]?.length;
            const allIds = data?.map((item) => item.id);
            if (selectedIdsCount === allIds?.length) {
                setSelectAllPages([...selectAllPages, page]);
            } else {
                const selectAllD = selectAllPages.filter((item) => item !== page);
                setSelectAllPages(selectAllD);
            }
        }
    };

    const handleChange = (id) => (e) => {
        const keyword = e.target.value;
        setSearch(e.target.value);
        if (liveCall) {
            let searchedData;
            if (id) {
                searchedData = filteredData.filter((item) => Object.values(item).some((value) => value.toString().toLowerCase().includes(keyword.toLowerCase())));
                if (keyword !== '') {
                    setLiveCallData(searchedData);
                } else {
                    setLiveCallData(filteredData);
                }
            } else {
                searchedData = liveCallData.filter((item) => Object.values(item).some((value) => value.toString().toLowerCase().includes(keyword.toLowerCase())));
                if (keyword !== '') {
                    setLiveCallData(searchedData);
                } else {
                    setLiveCallData(data);
                }
            }
        } else {
            const checkStatusExist = searchParams;
            if (checkStatusExist?.status !== undefined) {
                setSearchData({
                    ...searchParams, keyword: e.target.value, status: checkStatusExist?.status, page: 1,
                });
                // dispatch(handleSearchParams({
                //     ...searchParams, keyword: e.target.value, status: checkStatusExist?.status, page: 1,
                // }));
            } else {
                setSearchData({
                    ...searchParams, keyword: e.target.value, page: 1,
                });
            }
        }
    };

    const initialState = { hiddenColumns: deletePermission === false && editPermission === false ? ['ACTION'] : [] };
    const columnData = liveCall ? liveCallData : data;
    const tableInstance = useTable({
        columns,
        data: columnData,
        initialState,
    });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
    } = tableInstance;
    const toggleColumns = useCallback((col) => () => {
        col.toggleHidden();
        setTableColumn(allColumns);
        setTimeout(() => {
            const checkedAllVisibleOrNot = tableColumn.filter((item) => item.isVisible === true);
            const checkLength = checkedAllVisibleOrNot.length === allColumns.length;
            setAllChecked(checkLength);
        }, 100);
    });
    const toggleAllColumns = (event) => {
        getTableProps();
        allColumns.forEach((c) => {
            if (c.Header !== 'ACTIONS' && c.id !== 'name') {
                if (event.target.checked) {
                    !c.isVisible && c.toggleHidden();
                } else if (c.isVisible && c.Header !== 'ACTIONS' && c.id !== 'name') {
                    c.toggleHidden();
                }
            }
        });
        setAllChecked(!allChecked);
    };
    const handleStatusFilter = (event) => {
        let getStatus = [];
        if (event.target.checked) {
            if (searchParams?.status !== undefined && searchParams.status !== '') {
                getStatus = searchParams?.status?.split(',');
            }
            const checkStatusExistorNot = getStatus?.includes(event.target.value);
            if (!checkStatusExistorNot) {
                getStatus.push(event.target.value);
                const filterArr = { ...searchParams };
                filterArr.status = getStatus.toString();
                setStatusFilterData(getStatus.toString());
                filterArr.page = 1;
                if (filterArr) {
                    dispatch(handleSearchParams(filterArr));
                }
            }
        } else {
            if (searchParams?.status !== undefined) {
                getStatus = searchParams.status.split(',');
            }
            const checkStatusExistorNot = getStatus.includes(event.target.value);
            if (checkStatusExistorNot) {
                const alreadyAddedindex = (element) => element === event.target.value;
                const findIndex = getStatus.findIndex(alreadyAddedindex);
                if (findIndex !== -1) {
                    getStatus = getStatus.filter((item) => item !== event.target.value);
                }
                const filterArr = { ...searchParams };
                filterArr.status = getStatus.toString();
                setStatusFilterData(getStatus.toString());
                filterArr.page = 1;
                if (filterArr) {
                    dispatch(handleSearchParams(filterArr));
                }
            }
        }
    };
    const { data: Timezone } = useHandleGetApiQuery(
        {
            url: 'timezone?page=-1',
        },
        {
            refetchOnMountOrArgChange: true,
        },
    );
    const handleTimeZoneChange = (value) => {
        const val = value ? parseInt(value, 10) : '';
        dispatch(handleReportData({ ...reportData, timezone_id: val }));
        dispatch(handleSearchParams({
            keyword: search,
            page: 1,
            startDate: reportData.startDate,
            endDate: reportData.endDate,
            timezone_id: timeZone,
            advertiser_id: advertiserId,
        }));
        setTimeZone(val);
    };
    useEffect(() => {
        if (user?.time_zone) {
            setTimeZone(user.time_zone);
            const searchP = {
                page: 1,
                keyword: '',
                // timezone_id: user.time_zone,
            };
            setInitialSearchData(searchP);
        }
    }, [user]);

    const { data: timeData } = useHandleGetApiQuery({ url: 'globals/admin_timezone' });
    useEffect(() => {
        if (userType === PLATFORM_PROVIDER) {
            setTimeZone(timeData?.data?.id);
        }
        const searchP = {
            page: 1,
            keyword: '',
            // timezone_id: timeZone,
        };
        setInitialSearchData(searchP);
    }, [timeData]);
    const handleDelete = () => {
        handleSelectedAction({ action: selectAction, ids: selectedIds });
    };
    const handleClose = () => {
        setDeletePopup(false);
    };
    return (
        <>
            <CommonModdal
                show={deletePopup}
                type=""
                classAddon="theme-cyan text-center footer-center"
                size="small"
                heading=""
                openSubmitModal={handleDelete}
                handleClose={handleClose}
                footerButtons1={'Yes'}
                footerButtons2={'No'}
                isDelete
            >
                <img src={AlertIcon} className="mb-4" width="60" alt="clock" />
                {module ? (
                    <p>
                        {module === 'blacklist' ? 'Are you sure the selected number will be unblocked permanently?' : `Are you sure want to delete ${module && (`this ${module}`)} ?`}
                    </p>
                ) : <p>Are you sure want to delete ?</p>}

            </CommonModdal>
            <div className="col-md-12 mb-3">
                <CommonGlobalChipFilter
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    statusOptions={statusOptions}
                    setStatusOptions={setStatusOptions}
                    advertiserSearch={advertiserSearch}
                    componentName={componentName}
                    initialFilter={initialFilter}
                    setBlockedNumberFilter={setBlockedNumberFilter}
                    handleSearch={handleChange(advertiserId)}
                    searchData={searchData}
                    search={search}
                    setSearch={setSearch}
                    setSearchData={setSearchData}
                    nameSearch={searching}
                    setPage={setPage}
                    handleResetBulkData={handleResetData}
                    initialSearchData={initialSearchData}
                />
            </div>
            <div className="col-lg-12">
                <div className="card border-0 borderRadius-10 hidefilter">
                    {/* {isFetching && <Loader />} */}
                    <div className="header d-flex align-items-center justify-content-between pb-0">
                        <h2 className="setMmargin">{title}</h2>
                        <div className="table-right-side-menu flex-nowrap align-items-center gap-2 form-data">
                            {
                                (userType === PLATFORM_PROVIDER && advertiserSearch) && (
                                    <div className="advertiser-filter">
                                        <SearchableAdvertiserSelect onChange={handleAdvertiserChange} name="advertiser_id" label="advertiser" />
                                    </div>
                                )
                            }
                            {/* Timezone section :: BEGIN */}
                            {
                                showTimeZone && (
                                    <div className="filter-dropdown timezone-section">
                                        <SearchableSelect
                                            label="Timezone"
                                            options={Timezone?.data}
                                            onChange={handleTimeZoneChange}
                                            value={timeZone}
                                            name="time_zone"
                                            optionalFieldName="zone_name"
                                        />
                                    </div>
                                )
                            }
                            {/* Timezone section :: END */}

                            {/* Calender section :: BEGIN */}
                            {
                                showCalander && (
                                    <div className="filter-dropdown">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleDateRangeChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            isClearable
                                            maxDate={notRequiredMaxDateInDatePicker ? '' : currentDate}
                                            dateFormat="dd/MM/yyyy"
                                            className="themeInput datepickerrange-input"
                                            showYearDropdown
                                            showMonthDropdown
                                        />
                                    </div>
                                )
                            }
                            {/* Calender section :: END */}
                            {/* search section :: BEGIN */}
                            { searching && (
                                <input
                                    style={{ display: 'none' }} // Corrected style object
                                    type="text"
                                    placeholder="Name"
                                    className="themeInput"
                                    onChange={handleChange(advertiserId)}
                                    value={search}
                                />
                            )}

                            {(actions?.length > 0 && (
                                <div className="">
                                    <SearchableSelect
                                        label="Bulk Action"
                                        options={actions}
                                        onChange={handleActionChange}
                                        value={selectAction}
                                        name="action"
                                        isDisabled={selectedIds?.length === 0}
                                        withSearch={false}
                                    />
                                </div>

                            // <select
                            //     name="action"
                            //     className="form-control bulk-action-dropdown"
                            //     onChange={(e) => {
                            //         handleActionChange(e);
                            //     }}
                            //     disabled={selectedIds?.length === 0}
                            //     value={selectAction}
                            // >
                            //     <option value="">
                            //         Select Bulk Action
                            //     </option>
                            //     {actions.map((option, index) => (
                            //         <option key={index} value={option.id}>
                            //             {option.name}
                            //         </option>
                            //     ))}
                            // </select>
                            ))}
                            {/* search section :: END */}

                            {/* action dropdown section :: BEGIN */}
                            {columnFilter && (
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className="themeInput w-40 green-btn">
                                        <img src={filterIcon} alt="ICON" className="cursor-pointer" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="fancy-checkbox ml-2">
                                            <label>
                                                {/* <input {...getToggleHideAllColumnsProps()} type="checkbox" /> */}
                                                <input onClick={toggleAllColumns} type="checkbox" checked={allChecked} />
                                                <span>All</span>
                                            </label>
                                        </div>

                                        {allColumns.map((column) => (
                                            column.Header !== 'ACTIONS' && column.id !== 'name' ? (
                                                <div className="fancy-checkbox ml-2" key={Math.random()}>
                                                    <label>
                                                        {/* <input {...column.getToggleHiddenProps()} type="checkbox" /> */}
                                                        <input type="checkbox" onChange={toggleColumns(column)} checked={column.isVisible} />
                                                        <span className="chekbox-set"><p>{column.Header}</p></span>
                                                    </label>
                                                </div>
                                            ) : null

                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                            {allStatusFilter?.length > 0 && (
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className="themeInput w-40 green-btn">
                                        <img src={filterIcon} alt="ICON" className="cursor-pointer" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {allStatusFilter.map((filter) => (
                                            <div className="fancy-checkbox ml-2">
                                                <label>
                                                    <input
                                                        id={filter.value}
                                                        type="checkbox"
                                                        value={filter.value}
                                                        onChange={handleStatusFilter}
                                                        checked={searchParams?.status?.split(',')?.indexOf(filter.value) !== -1}
                                                    />
                                                    <span className="chekbox-set" htmlFor={filter.value}><p>{filter.option}</p></span>
                                                </label>
                                            </div>

                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                            {(userType === ADVERTISER && importCsv && (module === 'blacklist' || module === 'publisher')) && (
                                <button
                                    className="btn btn-secondary secondary-importbtn"
                                    onClick={handleImportCsv}
                                    type="button"
                                >
                                    Import CSV
                                </button>
                            )}
                            { (userType === ADVERTISER && createButton && createPermission) ? (
                                <button
                                    className="btn btn-primary text-nowrap"
                                    onClick={handleClick}
                                    type="button"
                                >
                                    {btnText}
                                </button>
                            ) : ''}
                            { (userType === PLATFORM_PROVIDER && importCsv && createPermission) ? (
                                <button
                                    className="btn btn-secondary secondary-importbtn"
                                    onClick={handleImportCsv}
                                    type="button"
                                >
                                    Import CSV
                                </button>
                            ) : ''}
                            { ((userType === ADVERTISER || userType === PLATFORM_PROVIDER) && ExportCsv) ? (
                                <button
                                    className="btn btn-secondary secondary-importbtn"
                                    onClick={handleExportCsv}
                                    type="button"
                                    disabled={columnData?.length === 0}
                                >
                                    Export CSV
                                </button>
                            ) : ''}
                            { ((userType === ADVERTISER || userType === PLATFORM_PROVIDER) && ExportPdf) ? (
                                <button
                                    className="btn btn-secondary secondary-importbtn export-pdf-btn"
                                    onClick={handleExportPdf}
                                    type="button"
                                    disabled={columnData?.length === 0}
                                >
                                    Export PDF
                                </button>
                            ) : ''}
                            { (userType === PLATFORM_PROVIDER && createButton) && (
                                <button
                                    className="btn btn-primary"
                                    onClick={handleClick}
                                    type="button"
                                >
                                    {btnText}
                                </button>
                            )}
                            {isRefetch && (
                                <div className="refresh-chart" onClick={handleRefetchRecord} title={'Refetch Data'}><i className="wi wi-refresh" /></div>
                            )}
                            {/* CREATE btn section :: END */}
                        </div>
                        {/* Filter section :: BEGIN */}
                        {/* Filter section :: END */}
                    </div>

                    {graphshow && (
                        <div className="graph-div">
                            <div className="graph-border pb-4" />
                            <h4 className="text-uppercase text-center pt-1">{graphTitle}</h4>
                            <ReactEcharts
                                option={graphOption}
                                style={{ height: 400 }}
                                opts={{ renderer: 'svg' }}
                            />
                        </div>
                    )}
                    {/* Show Chart section :: BEGIN */}
                    {showChart && (
                        <div className="chart-show-section">
                            <Reportchart setTimeInterval={setTimeInterval} timeInterval={timeInterval} setStartDate={setStartDate} setEndDate={setEndDate} datechange={datechange} startDate={startDate} endDate={endDate} />
                        </div>
                    )}
                    {/* Filter table section :: BEGIN */}
                    {showfilterTable && (
                        <>
                            {/* <div className="border-set" /> */}
                            <div className={`filtertable-section ${showCommonChipsFilter ? 'pt-3' : ''}`}>
                                <div className="table-responsive filterTable-data p-0">
                                    {/*  */}
                                    {/* Show Chart section :: END */}
                                    <table className="table parentTable" {...getTableProps()}>
                                        <thead>
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => <th {...column.getHeaderProps()}>{column.render('Header')}</th>)}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {!isFetching && !isLoading
                                                ? rows.length > 0 ? rows.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <>
                                                            <tr
                                                                aria-label="Click to view detail"
                                                                {...row.getRowProps()}
                                                                onClick={() => {
                                                                    setIsLiveCallTableOpen(!isLiveCallTableOpen);
                                                                    if (isLiveCallTableOpen && row.original.target_id === idObjectForAdvertiser.target_id && row.original.campaign_id === idObjectForAdvertiser.campaign_id && userType === ADVERTISER) {
                                                                        setIsLiveCallTableOpen(false);
                                                                        setIdObjectForAdvertiser({});
                                                                        if (showPagination) {
                                                                            handleFilterTablePage(1);
                                                                        }
                                                                    } else if (isLiveCallTableOpen && row.original.campaign_id === idObjectForAdvertiser.campaign_id && row.original.advertiser_id === idObjectForAdvertiser.advertiser_id && userType === PLATFORM_PROVIDER) {
                                                                        setIsLiveCallTableOpen(false);
                                                                        setIdObjectForAdvertiser({});
                                                                        if (showPagination) {
                                                                            handleFilterTablePage(1);
                                                                        }
                                                                    } else {
                                                                        setIsLiveCallTableOpen(true);
                                                                        setIdObjectForAdvertiser({
                                                                            campaign_id: row.original.campaign_id, target_id: row.original?.target_id, advertiser_id: row.original.advertiser_id, blacklisted_caller: blackNumberExcluded,
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                {row.cells.map((cell) => <td className="cursor-pointer" {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
                                                            </tr>
                                                            {/* Advertiser Inner table Started */}
                                                            {/* {isLiveCallTableOpen && row.original.campaign_id === idObjectForAdvertiser?.campaign_id && row.original.target_id === idObjectForAdvertiser?.target_id && (
                                                                        <tr>
                                                                            <td className="child-td" colSpan={row.cells.length}>
                                                                                <FilterTable individualColumns={individualColumns} individualData={individualData} dataLoader={dataLoader} doNotScroll showPagination={showPagination} handleFilterTablePage={handleFilterTablePage} currentFilterTablePage={currentFilterTablePage} />
                                                                            </td>
                                                                        </tr>
                                                                    )} */}
                                                            {isLiveCallTableOpen && userType === ADVERTISER && row.original.campaign_id === idObjectForAdvertiser?.campaign_id && row.original.target_id === idObjectForAdvertiser?.target_id && (
                                                                <tr>
                                                                    <td className="child-td" colSpan={row.cells.length}>
                                                                        <FilterTable individualColumns={individualColumns} individualData={individualData} dataLoader={dataLoader} doNotScroll showPagination={showPagination} handleFilterTablePage={handleFilterTablePage} currentFilterTablePage={currentFilterTablePage} />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {isLiveCallTableOpen && userType === PLATFORM_PROVIDER && row.original.campaign_id === idObjectForAdvertiser?.campaign_id && row.original.advertiser_id === idObjectForAdvertiser?.advertiser_id && (
                                                                <tr>
                                                                    <td className="child-td" colSpan={row.cells.length}>
                                                                        <FilterTable individualColumns={individualColumns} individualData={individualData} dataLoader={dataLoader} doNotScroll showPagination={showPagination} handleFilterTablePage={handleFilterTablePage} currentFilterTablePage={currentFilterTablePage} />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {/* Advertiser Inner Table Ended */}
                                                        </>
                                                    );
                                                }) : (
                                                    <tr>
                                                        <td className="text-center" colSpan={100}><p>No data available.</p></td>
                                                    </tr>
                                                )
                                                : (
                                                    <tr>
                                                        <td className="text-center" colSpan={100}><TableLoader /></td>
                                                    </tr>

                                                ) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}

                    {/* Filter table section :: END */}
                    {!showfilterTable && (
                        <div className="body table-responsive">
                            {/*  */}
                            {/* Show Chart section :: END */}
                            <table className="table table-hover" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    id={'checkbox-all'}
                                                    onChange={(e) => handleSelectedIdsforAction(e, 'all')} // Pass rowId to the handler
                                                    name="action-checkbox"
                                                    value={'all'}
                                                    disabled={data?.length === 0 || isLoading || isFetching}
                                                    checked={selectAllPages.includes(page)}
                                                />
                                            </th>
                                            {headerGroup.headers.map((column) => <th {...column.getHeaderProps()}>{column.render('Header')}</th>)}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {!isFetching && !isLoading ? rows.length > 0 ? rows.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox-${data?.[row.id]?.id}`}
                                                        onChange={(e) => handleSelectedIdsforAction(e, data?.[row.id]?.id)} // Pass rowId to the handler
                                                        value={data?.[row.id]?.id}
                                                        checked={selectedIds.includes(data?.[row.id]?.id)}
                                                    />
                                                </td>
                                                {row.cells.map((cell) => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
                                            </tr>

                                        );
                                    }) : (
                                        <tr>
                                            <td className="text-center" colSpan={100}><p>No data available.</p></td>
                                        </tr>
                                    )
                                        : (
                                            <tr>
                                                <td className="text-center" colSpan={100}><TableLoader /></td>
                                            </tr>
                                        ) }
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

BasicTableWithGlobalSearch.propTypes = {
    title: PropTypes.string,
    handleClick: PropTypes.func,
    handlePortClick: PropTypes.func,
    btnText: PropTypes.string,
    secondBtnText: PropTypes.string,
    data: PropTypes.instanceOf(Object),
    columns: PropTypes.instanceOf(Object),
    advertiserColumns: PropTypes.instanceOf(Object),
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    createButton: PropTypes.bool,
    portButton: PropTypes.bool,
    deletePermission: PropTypes.bool,
    editPermission: PropTypes.bool,
    importCsv: PropTypes.bool,
    handleImportCsv: PropTypes.func,
    columnFilter: PropTypes.bool,
    statusFilter: PropTypes.instanceOf([object]),
    searching: PropTypes.bool,
    ExportCsv: PropTypes.bool,
    ExportPdf: PropTypes.bool,
    handleExportCsv: PropTypes.func,
    handleExportPdf: PropTypes.func,
    showCalander: PropTypes.bool,
    showTimeZone: PropTypes.bool,
    showChart: PropTypes.bool,
    showCommonChipsFilter: PropTypes.bool,
    filterOptions: PropTypes.array,
    initialFilter: PropTypes.array,
    setFilterOptions: PropTypes.func,
    statusOptions: PropTypes.array,
    setStatusOptions: PropTypes.func,
    showfilterTable: PropTypes.bool,
    componentName: PropTypes.string,
    datepickerRange: PropTypes.instanceOf([object]),
    advertiserSearch: PropTypes.bool,
    datechangeFilter: PropTypes.func,
    handleRefetchRecord: PropTypes.func,
    isRefetch: PropTypes.bool,
    liveCall: PropTypes.bool,
    notRequiredMaxDateInDatePicker: PropTypes.bool,
    setIsLiveCallTableOpen: PropTypes.func,
    isLiveCallTableOpen: PropTypes.bool,
    idObjectForAdvertiser: PropTypes.instanceOf(Object),
    setIdObjectForAdvertiser: PropTypes.func,
    individualColumns: PropTypes.instanceOf(Object),
    individualData: PropTypes.instanceOf(Object),
    dataLoader: PropTypes.bool,
    showPagination: PropTypes.bool,
    setDataLoader: PropTypes.func,
    handleFilterTablePage: PropTypes.func,
    currentFilterTablePage: PropTypes.number,
    module: PropTypes.number,
    setTimeInterval: PropTypes.func,
    timeInterval: PropTypes.number,
    searchRequired: PropTypes.bool,
    refetchDetailRecord: PropTypes.func,
    graphOption: PropTypes.instanceOf(Object),
    graphshow: PropTypes.bool,
    graphTitle: PropTypes.string,
    actions: PropTypes.array,
    handleSelectedAction: PropTypes.func,
    setPage: PropTypes.func,
    resetIdsToggle: PropTypes.bool,
    setResetToggle: PropTypes.func,
    page: PropTypes.number,
    resetAction: PropTypes.func,
};
BasicTableWithGlobalSearch.defaultProps = {
    createButton: false,
    portButton: false,
    deletePermission: true,
    editPermission: true,
    importCsv: false,
    ExportCsv: false,
    ExportPdf: false,
    columnFilter: true,
    statusFilter: {},
    searching: true,
    showCalander: false,
    showTimeZone: false,
    advertiserSearch: false,
    componentName: '',
    isRefetch: false,
    notRequiredMaxDateInDatePicker: false,
    graphshow: false,
    graphOption: {},
    graphTitle: '',
};

export default BasicTableWithGlobalSearch;
