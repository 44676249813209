/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import CommonModdal from '../../components/Common/CommonModal';
import fileExport from '../../assets/images/file-export.svg';
import CampaignLoader from '../CampaignLoader';

const ExportCSVPopup = ({
    show, handleClose, handleSubmit, type, loader,

}) => (
    <CommonModdal
        show={show}
        type=""
        classAddon="theme-cyan text-center footer-center"
        size="small"
        heading=""
        openSubmitModal={handleSubmit}
        handleClose={handleClose}
        footerButtons1={'Yes'}
        footerButtons2={'No'}
        isDelete
    >
        <img src={fileExport} className="mb-4" width="60" alt="clock" />
        <p>
            {`Are you sure want to export ${type}?`}
        </p>
        <p>
            <strong>Note: </strong>
            Maximum 10,000 recods will be export
            {' '}
            <br />
            at the time.
        </p>
        {loader && <CampaignLoader type="export" /> }
    </CommonModdal>
);

ExportCSVPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    type: PropTypes.string,
    loader: PropTypes.bool,
};
ExportCSVPopup.defaultProps = {
    type: 'CSV',
};
export default ExportCSVPopup;
