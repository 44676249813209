/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import InputWithTooltip from '../../components/Common/InputWithTooltip';
import withPostContainer from '../../Container/withPostContainer';
import { whiteListIpAddressSchema } from '../../Validation';
import ErrorMessageComponent from '../../components/Common/FormikErrorMessage';
import Loader from '../loader';
import { handleSearchParams } from '../../app/features/CommonSlice';
import TooltipContainer from '../../components/Common/TooltipConatainer';

const AddPlan = ({
    handlePostRequest, id, data, dataLoading,
}) => {
    const dispatch = useDispatch();
    const [serverData, setServerData] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const initialValues = {
        ip: '',
        description: '',
        name: '',
    };
    const formik = useFormik({
        initialValues: serverData || initialValues,
        validationSchema: whiteListIpAddressSchema,
        onSubmit: (values, { setSubmitting }) => {
            const url = id ? `whitelistips/${id}` : 'whitelistips/';
            handlePostRequest({
                data: values,
                url,
                redirect: '/whitelist-IP-address',
                setSubmitting,
                isUpdate: !!id,
                formik,
            });
        },
        enableReinitialize: true,
    });
    useEffect(() => {
        if (data) {
            setServerData({
                ...data?.data,
            });
        }
    }, [data]);

    const handleCancel = () => {
        dispatch(handleSearchParams({ page: 1, search: '' }));
        navigate('/whitelist-IP-address');
    };
    return (
        <div className="container-fluid">
            <PageHeader
                HeaderText="Whitelist IP Address Management"
                Breadcrumb={[
                    { name: 'IP Address Management', navigate: '/whitelist-IP-address' },
                    { name: id ? 'Edit whitelist IP Address Parameter' : 'Create whitelist IP Address Parameter', navigate: '' },
                ]}
            />
            <div className="col-12 p-0">
                <div className="card themeTabs body">
                    <div className="card-tab-header">
                        <ul className="nav nav-tabs-new">
                            <li className="nav-item mr-1 active">
                                <div className="nav-link active">
                                    {id ? 'Edit Whitelist IP Address' : 'Add Whitelist IP Address'}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content">
                        {(dataLoading) ? <div className="div-center"><Loader /></div> : (
                            <form>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <InputWithTooltip
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            label="Name"
                                            text="A friendly name to identify the whitelisted IP"
                                            required
                                        />
                                        <ErrorMessageComponent error={formik.errors.name} touched={formik.touched.name} />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <InputWithTooltip
                                            name="ip"
                                            value={formik.values.ip}
                                            onChange={formik.handleChange}
                                            label="IP Address"
                                            text="The specific IP address to be whitelisted."
                                            required
                                        />
                                        <ErrorMessageComponent error={formik.errors.ip} touched={formik.touched.ip} />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <TooltipContainer label="Description" text="Brief information or notes about the purpose of whitelisting.">
                                            <div className="form-group mb-3">
                                                <textarea
                                                    rows={3}
                                                    id="description"
                                                    name="description"
                                                    value={formik.values.description}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>

                                        </TooltipContainer>
                                        <ErrorMessageComponent error={formik.errors.description} touched={formik.touched.description} />
                                    </div>
                                    <div className="col-12 pt-4">
                                        <button type="button" className="btn  btn-primary mr-3 " onClick={formik.handleSubmit} disabled={formik.isSubmitting}>{` ${id ? 'Update' : 'Create'}`}</button>
                                        <button type="button" className="btn  btn-secondary mr-3 " onClick={handleCancel}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
AddPlan.propTypes = {
    handlePostRequest: PropTypes.func.isRequired,
    dataLoading: PropTypes.bool.isRequired,
};

export default withPostContainer(AddPlan, 'whitelistips/');
