/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useHandleGetApiQuery } from '../../app/features/api/CommonGetApi';
import { useHandleUpdateOrDeleteMutation } from '../../app/features/api/CommonChangeApi';
import CommonModdal from '../../components/Common/CommonModal';
import { Toast } from '../../components/Common/Toaster';
import AlertIcon from '../../assets/images/alert-icon.svg';
import { ADVERTISER, PLATFORM_PROVIDER } from '../../Constant';

const withGetContainer = ({
    WrappedComponent, url, module,
}) => {
    const newComponent = () => {
        const navigate = useNavigate();
        const { permissions } = useSelector((store) => store.auth);
        const { searchParams } = useSelector((store) => store.common);
        const [showDelete, setShowDelete] = useState(false);
        const [deleteData, setDeleteData] = useState({});
        const [handleUpdateOrDelete, { isLoading: deleteLoading }] = useHandleUpdateOrDeleteMutation();
        const { userType } = useSelector((store) => store.auth);
        let modifiedUrl;
        if (userType === ADVERTISER && module === 'live-call') {
            modifiedUrl = '/calls/advertisers/livecalls/counts';
        } else if (userType === PLATFORM_PROVIDER && module === 'live-call') {
            modifiedUrl = '/calls/livecalls/counts';
        } else if (module === 'cdr-reports') {
            modifiedUrl = '/report/call_details/campaigns';
        } else {
            modifiedUrl = url;
        }
        const shouldSkip = Object.keys(searchParams).length === 0;
        // Conditional object setup based on `searchParams` being empty or not
        const queryParams = shouldSkip
            ? { skip: true } // Skip if searchParams is empty
            : { url: modifiedUrl, params: searchParams };
        const {
            data, isLoading, isFetching, error, refetch, isSuccess,
        } = useHandleGetApiQuery(
            queryParams,
            searchParams,
            modifiedUrl,
            {
                refetchOnMountOrArgChange: true,
                refetchOnFocus: true,
            },
        );

        if (error?.data?.status?.code === 401) {
            navigate('/dashboard');
        }

        const handleDeleteOpen = useCallback((deleteUrl, type, message) => () => {
            if (type) {
                Toast({ type: 'error', message });
            } else {
                setShowDelete(true);
                setDeleteData({ url: deleteUrl });
            }
        }, []);

        const refetchRecord = useCallback(() => {
            refetch();
        });

        const handleDelete = async () => {
            const res = await handleUpdateOrDelete({ url: `${deleteData.url}`, method: 'DELETE' });
            if (res?.data?.status === 200) {
                setShowDelete(false);
                Toast({ message: res?.data.message });
            }
        };

        const handleClose = () => {
            setShowDelete(false);
        };

        return (
            <>
                <CommonModdal
                    show={showDelete}
                    type=""
                    classAddon="theme-cyan text-center footer-center"
                    size="small"
                    heading=""
                    openSubmitModal={handleDelete}
                    handleClose={handleClose}
                    footerButtons1={module === 'blacklist' ? 'OK' : 'Yes'}
                    footerButtons2={module === 'blacklist' ? 'Cancel' : 'No'}
                    isDelete
                    disabled={deleteLoading}
                >
                    <img src={AlertIcon} className="mb-4" width="60" alt="clock" />
                    {module ? (
                        <p>
                            {module === 'blacklist' ? 'Are you sure the selected number will be unblocked permanently?' : `Are you sure want to delete ${module && (`this ${module}`)} ?`}
                        </p>
                    ) : <p>Are you sure want to delete ?</p>}

                </CommonModdal>
                <WrappedComponent
                    isSuccess={isSuccess}
                    data={data}
                    isLoading={isLoading}
                    permissions={permissions}
                    isFetching={isFetching}
                    handleDeleteMethod={handleDeleteOpen}
                    refetchRecord={refetchRecord}
                />
            </>
        );
    };

    return newComponent;
};

export default withGetContainer;
