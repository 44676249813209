import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LoaderIcon from '../assets/images/loader-icon.png';

const Loader = ({ doNotScroll }) => {
    useEffect(() => {
        if (!doNotScroll) window.scrollTo(0, 0);
    }, []);

    return (

        <div className="main-loader">
            <div className="loader-icon-container">
                <img src={LoaderIcon} alt="Loader Icon" className="loader-icon" />
            </div>
        </div>

    );
};
export default Loader;

Loader.propTypes = {
    doNotScroll: PropTypes.bool.isRequired,
};
