/* eslint-disable react/no-unknown-property */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import withPostContainer from '../../Container/withPostContainer';
import PageHeader from '../../components/PageHeader';
import TooltipContainer from '../../components/Common/TooltipConatainer';
import Loader from '../loader';
import { convertToBase64, getModulePermission } from '../../Util';
import { dynamicContentValidation } from '../../Validation';
import ErrorMessageComponent from '../../components/Common/FormikErrorMessage';
import { handleSearchParams } from '../../app/features/CommonSlice';
import CommonModdal from '../../components/Common/CommonModal';
import addBalanceIcon from '../../assets/images/add-balance-icon.png';

const EditDynamicContent = ({
    handlePostRequest, id, data, dataLoading,
}) => {
    getModulePermission('view', 'page');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [uploadImagesArr, setUploadImagesArr] = useState([{
        image: null, link: '', image_name: '', image_preview: { url: '', content_type: '', convertedImage: '' },
    }]);
    const [preview, setPreview] = useState(false);
    const [previewImage, setPreviewImage] = useState({});
    const [aspectRatioError, setAspectRatioError] = useState([]);

    const customConfig = {
        toolbar: {
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'blockQuote',
                'insertTable',
                '|',
                'undo',
                'redo',
            ],
        },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
    };
    const [serverData, setServerData] = useState();
    const initialValues = {
        title: '',
        title_link: '',
        images: [
            {
                image: null, link: '', image_name: '', newImg: true, image_preview: { url: '', content_type: '', convertedImage: '' },
            },
        ],
        description: '',
        button_text: '',
        button_url: '',
    };
    const formik = useFormik({
        initialValues: serverData || initialValues,
        validationSchema: dynamicContentValidation,
        onSubmit: async (values, { setSubmitting }) => {
            const getAllEspectRatioError = aspectRatioError.filter((item) => item === true);

            if (getAllEspectRatioError?.length === 0) {
                let uploadImg = [];
                values.images.map((item) => {
                    uploadImg = [...uploadImg, { image: item.convertedImage, link: item.link }];
                });
                values.images = uploadImg;
                const url = `dynamic/${id}`;
                handlePostRequest({
                    data: values,
                    redirect: '/dynamic-contents',
                    isUpdate: !!id,
                    url,
                    setSubmitting,
                    formik,
                });
            }
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        console.log(formik.errors.images);
    }, [formik.errors.images]);
    // useEffect(() => {
    //     console.log(formik.errors);
    // }, [formik.errors]);
    useEffect(() => {
        if (data) {
            const dataArr = data?.data;
            setServerData({
                title: dataArr.title,
                slug: dataArr.slug,
                title_link: dataArr.title_link,
                description: dataArr.description,
                button_url: dataArr.button_url,
                button_text: dataArr.button_text,
            });
            if (dataArr.images?.length > 0) {
                let uploadData = [];
                dataArr.images?.map((item) => {
                    uploadData = [...uploadData, {
                        image: item.image, newImg: false, image_name: item.image, image_preview: { url: item.image_url?.url, content_type: item.image_url?.content_type }, link: item.link, convertedImage: item.image,
                    }];
                });
                setUploadImagesArr(uploadData);
            }
        }
    }, [data]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleExpenseSubmit = () => {
        if (Object.keys(formik.errors).length > 0) {
            const firstError = document.querySelector('.formik-error');
            if (firstError) {
                (firstError?.parentElement ?? firstError)?.scrollIntoView();
                formik.handleSubmit();
            }
        } else {
            formik.handleSubmit();
        }
    };
    const handleCancel = () => {
        dispatch(handleSearchParams({ page: 1, search: '' }));
        navigate('/dynamic-contents');
    };
    const handleLinkChange = (e, i) => {
        const updatedData = [...formik.values.images];
        updatedData[i].link = e.target.value;
        setUploadImagesArr(updatedData);
    };
    const handleImageChange = useCallback(async (event, index) => {
        const { files } = event.target;
        if (files.length === 0) return;
        const imageFile = files[0];
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);

        img.onload = () => {
            const aspectRatio1 = img.width / img.height;
            const aspectRatioErrorData = [...aspectRatioError];
            if (parseFloat(aspectRatio1?.toFixed(2)) !== parseFloat(3.82)) {
                aspectRatioErrorData[index] = true;
                setAspectRatioError(aspectRatioErrorData);
            } else {
                aspectRatioErrorData[index] = false;
                setAspectRatioError(aspectRatioErrorData);
            }
        };
        const updatedUploadData = [...formik.values.images];
        const currentImageData = updatedUploadData[index] || {};
        updatedUploadData[index].image = imageFile;
        updatedUploadData[index].convertedImage = await convertToBase64(imageFile);
        updatedUploadData[index].link = currentImageData.link || '';
        updatedUploadData[index].image_name = imageFile.name || '';
        updatedUploadData[index].newImg = true;
        updatedUploadData[index].image_preview = { url: URL.createObjectURL(imageFile), content_type: imageFile?.content_type };
        setUploadImagesArr(updatedUploadData);
    });
    useEffect(() => {
        formik.setFieldValue('images', uploadImagesArr);
    }, [uploadImagesArr]);
    // const handleImageRemove = ((i) => {
    //     // const getFormikValues = formik.values.images;
    //     // getFormikValues[i] = {
    //     //     image: '', link: '', image_name: '',
    //     // };
    //     const arraySlice = uploadImagesArr.splice(i);
    //     setUploadImagesArr(arraySlice);
    // });
    const handleImageShow = ((pre) => {
        setPreviewImage({ url: pre.url, type: pre?.content_type });
        setPreview(true);
    });
    const handleClosePreview = (() => {
        setPreview(false);
        setPreviewImage('');
    });
    const handleAddImage = (() => {
        if (uploadImagesArr?.length < 5) {
            const carrArr = {
                image: '', link: '', image_preview: { url: '', content_type: '' }, image_name: '', newImg: true, convertedImage: '',
            };
            setUploadImagesArr([...uploadImagesArr, carrArr]);
        }
    });

    return (
        <div className="container-fluid">
            <PageHeader
                HeaderText="Dynamic Content"
                Breadcrumb={[
                ]}
            />
            <CommonModdal
                show={preview}
                handleClose={handleClosePreview}
                heading
                type="priceModal"
            >
                { previewImage?.url && (
                    <div style={{
                        width: '400px', maxHeight: '450px', margin: 'auto', textAlign: 'center',
                    }}
                    >
                        <img className="img-fluid" src={previewImage?.url} alt="preview-logo" />
                    </div>
                )}
            </CommonModdal>
            <div className="col-12 p-0 form-data">
                <div className="card themeTabs body">
                    <div className="card-tab-header">
                        <ul className="nav nav-tabs-new">
                            <li className="nav-item mr-1 active">
                                <div className="nav-link active">
                                    {id ? 'Edit Details' : 'Add Details' }
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content">
                        {(dataLoading) ? <div className="div-center"><Loader /></div> : (
                            <form>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <TooltipContainer label="Title" id="title" text="Enter the main heading for your content." required>
                                            <input className="form-control" type="text" name="title" value={formik.values.title} onChange={formik.handleChange} />
                                            {/* </div> */}
                                        </TooltipContainer>
                                        <div className="mt-2">
                                            <ErrorMessageComponent error={formik.errors.title} touched={formik.touched.title} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <TooltipContainer label="Title Link" id="title_link" text="Provide a URL for the title that users can click on." required>
                                            <input className="form-control" type="text" name="title_link" value={formik.values.title_link} onChange={formik.handleChange} />
                                            {/* </div> */}
                                        </TooltipContainer>
                                        <div className="mt-2">
                                            <ErrorMessageComponent error={formik.errors.title_link} touched={formik.touched.title_link} />
                                        </div>
                                    </div>
                                    {formik.values.images.map((UploadImg, i) => (
                                        <div className="col-12" key={i}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <TooltipContainer label="Upload Image" id={`upload_image_${i}`} text="Select and upload an image file from your device." required>
                                                        <div className="form-group mb-1">
                                                            <label htmlFor={`image_${i}`} className="form-control image-name-label">
                                                                {UploadImg?.image_name}
                                                            </label>
                                                            <div className="d-flex align-items-start gap-1">
                                                                <div className="position-relative">
                                                                    {/* <input
                                                                    id={`image_${i}`}
                                                                    name="image"
                                                                    className="form-control"
                                                                    type="text"
                                                                    onChange={(event) => handleImageChange(event, i)}
                                                                /> */}
                                                                    <input
                                                                        id={`image_${i}`}
                                                                        name="image"
                                                                        className="upload-payment-logo form-control"
                                                                        type="file"
                                                                        accept="image/jpeg,image/gif,image/png"
                                                                        onChange={(event) => handleImageChange(event, i)}
                                                                    />
                                                                    <button
                                                                        className="btn btn-success"
                                                                        type="button"
                                                                        htmlFor="upl"
                                                                    >
                                                                        Upload
                                                                    </button>
                                                                </div>
                                                                <button
                                                                    className="btn btn-info"
                                                                    type="button"
                                                                    onClick={() => handleImageShow(UploadImg.image_preview)}
                                                                    disabled={!UploadImg.image}
                                                                >
                                                                    Preview
                                                                </button>
                                                                { formik.values.images?.length > 1 && (
                                                                    <div className="margin-top-2px cursor-pointer">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="31"
                                                                            height="31"
                                                                            viewBox="0 0 40 39"
                                                                            fill="none"
                                                                            onClick={() => {
                                                                                const newArray = uploadImagesArr.filter((_, index) => index !== i);
                                                                                setUploadImagesArr(newArray);
                                                                            }}
                                                                        >
                                                                            <title>Remove Image</title>
                                                                            <rect x="0.5" width="39" height="39" rx="10" fill="#FD1D1D" />
                                                                            <path d="M21.4143 19.5L29.2072 11.7071C29.5977 11.3166 29.5977 10.6834 29.2072 10.2929C28.8166 9.90237 28.1834 9.90237 27.7929 10.2929L20 18.0858L12.2071 10.2929C11.8166 9.90237 11.1834 9.90237 10.7929 10.2929C10.4024 10.6834 10.4024 11.3166 10.7929 11.7071L18.5858 19.5L10.7929 27.2929C10.4024 27.6834 10.4024 28.3166 10.7929 28.7071C11.1834 29.0976 11.8166 29.0976 12.2071 28.7071L20 20.9142L27.7929 28.7071C28.1834 29.0976 28.8166 29.0976 29.2071 28.7071C29.5976 28.3166 29.5976 27.6834 29.2071 27.2929L21.4143 19.5Z" fill="white" />
                                                                        </svg>
                                                                    </div>
                                                                )}
                                                                {i < 4 && (formik.values.images?.length === (i + 1)) && (
                                                                    <div className="add_icon ml-1 margin-top-2px">
                                                                        <img src={addBalanceIcon} alt="ICON" onClick={handleAddImage} title="Add Image " />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <p className="small-text dynamic-page-image-note">File size must be less than 5MB. Image height width aspect ratio should be 3.82:1</p>
                                                    </TooltipContainer>
                                                    <div className="">
                                                        <ErrorMessageComponent error={formik.errors.images?.[i]?.image} touched={formik.touched.images?.[i]?.image} />
                                                    </div>
                                                    {aspectRatioError[i] && !formik.errors.images?.[i]?.image && (
                                                        <div className="">
                                                            <h6
                                                                className="formik-error"
                                                                style={{
                                                                    fontSize: '.9em',
                                                                    color: '#FC3C3C',
                                                                    fontWeight: 400,
                                                                    transition: '.2s all',
                                                                }}
                                                            >
                                                                Image height width aspect ratio should be 3.82:1
                                                            </h6>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-md-6">
                                                    <TooltipContainer label="Image Link" id={i} text="Enter the URL that the uploaded image should link to." required>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="link"
                                                            value={UploadImg.link}
                                                            onChange={(event) => handleLinkChange(event, i)}

                                                        />
                                                    </TooltipContainer>
                                                    <div className="mt-2">
                                                        <ErrorMessageComponent error={formik.errors.images?.[i]?.link} touched={formik.touched.images?.[i]?.link} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="col-12 ckeditor-section">
                                        <TooltipContainer label="Description" id="description" text="Write a brief summary  of the content." required>
                                            <CKEditor
                                                config={customConfig}
                                                editor={ClassicEditor}
                                                data={formik.values.description}
                                                onChange={(event, editor) => {
                                                    const dataC = editor.getData();
                                                    formik.setFieldValue('description', dataC);
                                                }}
                                            />
                                        </TooltipContainer>

                                        <ErrorMessageComponent error={formik.errors.description} touched={formik.touched.description} />

                                    </div>
                                    <div className="col-12 col-md-6">
                                        <TooltipContainer label="Button Text" id="button_text" text="Specify the text to be displayed on the button." required>
                                            <input className="form-control" type="text" name="button_text" value={formik.values.button_text} onChange={formik.handleChange} />
                                            {/* </div> */}
                                        </TooltipContainer>
                                        <div className="mt-2">
                                            <ErrorMessageComponent error={formik.errors.button_text} touched={formik.touched.button_text} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <TooltipContainer label="Button Link" id="button_url" text="Provide the URL that the button should direct users to." required>
                                            <input className="form-control" type="text" name="button_url" value={formik.values.button_url} onChange={formik.handleChange} />
                                            {/* </div> */}
                                        </TooltipContainer>
                                        <div className="mt-2">
                                            <ErrorMessageComponent error={formik.errors.button_url} touched={formik.touched.button_url} />
                                        </div>
                                    </div>

                                    {/* Accordin select option loop :: END */}
                                    <div className="col-12 mt-2">
                                        <button className="btn btn-primary" type="button" onClick={handleExpenseSubmit}>Save</button>
                                        <button className="btn btn-secondary ml-3" type="button" onClick={handleCancel}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
EditDynamicContent.propTypes = {
    handlePostRequest: PropTypes.func.isRequired,
    dataLoading: PropTypes.bool.isRequired,
};
export default withPostContainer(EditDynamicContent, 'dynamic/');
