/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import CommonModdal from '../../../components/Common/CommonModal';
import AlertIcon from '../../../assets/images/alert-icon.svg';

const PaymentStatusChangePopup = ({
    show, handleClose, handleSubmit,
    selectedPaymentMethodInfo, disabledLoading,
}) => (
    <CommonModdal
        show={show}
        type=""
        classAddon="theme-cyan text-center footer-center"
        size="small"
        heading=""
        openSubmitModal={handleSubmit}
        handleClose={handleClose}
        footerButtons1="Yes"
        footerButtons2="No"
        isDelete
        disabled={disabledLoading}
    >
        <img src={AlertIcon} className="mb-4" width="60" alt="clock" />
        {selectedPaymentMethodInfo?.name ? (
            <p>
                Are you sure want to switch
                {' '}
                {selectedPaymentMethodInfo?.name && (
                    <b>
                        &nbsp;
                        {selectedPaymentMethodInfo?.name}
                    </b>
                )}
                {' '}
                payment status ?
            </p>
        )
            : (
                <p>
                    {' '}
                    Are you sure want to switch payment status ?
                </p>
            )}
    </CommonModdal>
);
PaymentStatusChangePopup.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectedPaymentMethodInfo: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    handleSubmit: PropTypes.func.isRequired,
    disabledLoading: PropTypes.bool,
};
export default PaymentStatusChangePopup;
