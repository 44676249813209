/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PageHeader from '../../components/PageHeader';
import withPostContainer from '../../Container/withPostContainer';
import { PublisherValidationSchema } from '../../Validation';
import InputWithTooltip from '../../components/Common/InputWithTooltip';
import ErrorMessageComponent from '../../components/Common/FormikErrorMessage';
import { getModulePermission } from '../../Util';
import TooltipContainer from '../../components/Common/TooltipConatainer';
import Loader from '../loader';
import { handleSearchParams } from '../../app/features/CommonSlice';
import SearchableSelect from '../../components/Common/CommonSearchableSelect';
// import { useHandleGetWithPostMutation } from '../../app/features/api/CommonGetWithPostApi';
import { useHandleGetApiQuery } from '../../app/features/api/CommonGetApi';
// import InputWitduplicate_call_lengthadio from '../../components/Common/InputWitduplicate_call_lengthadio';

const AddPublisher = ({
 handlePostRequest, id, data, dataLoading,
}) => {
  if (id) {
    getModulePermission('update', 'page');
  } else {
    getModulePermission('create', 'page');
  }
  const [serverData, setServerData] = useState();
  const navigat = useNavigate();
  const dispatch = useDispatch();
  const [campaignList, setCampaignList] = useState([]);
  const { user } = useSelector((store) => store.auth);
  const { data: PermissionList } = useHandleGetApiQuery({
    url: 'publisher/permission-list',
  });
  const { data: CampaignList } = useHandleGetApiQuery({
    url: `publisher/campaigns?publisher_id=${id || null}`,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    if (CampaignList?.data) {
      const camD = CampaignList?.data.map((item) => ({
        id: item.campaign_id,
        campaign_name: item.campaign_name,
        purchased_did_numbers: item.purchased_did_numbers,
      }));
      setCampaignList(camD);
    }
  }, [CampaignList?.data]);
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    campaign: false,
    campaign_details: [],
    blacklist: false,
    blacklist_details: [],
    live_call: false,
    create_did: false,
    live_call_details: [],
    cdr: false,
    cdr_details: [],
  };
  const formik = useFormik({
    initialValues: serverData || initialValues,
    validationSchema: PublisherValidationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const nValues = values;
      if (!nValues.campaign) {
        nValues.campaign_details = [];
        nValues.purchased_did_number = [];
      }

      if (!nValues.blacklist) {
        nValues.blacklist_details = [];
      }

      if (!nValues.live_call) {
        nValues.live_call_details = [];
      }

      if (!nValues.cdr) {
        nValues.cdr_details = [];
      }
      const url = id ? `/publisher/${id}` : '/publisher/';
      handlePostRequest({
        data: nValues,
        url,
        redirect: '/publisher-list',
        setSubmitting,
        isUpdate: !!id,
        formik,
      });
    },
  });
  const handleDidChange = (e, index) => {
    const values = [...e.target.selectedOptions].map((opt) => parseInt(opt.value, 10));
    const campaignData = [...formik.values.campaign_details];
    const updatedItem = {
      ...campaignData[index],
      purchased_did_numbers: values,
    };
    campaignData[index] = updatedItem;
    campaignData[index].purchased_did_numbers = values;
    formik.setFieldValue('campaign_details', campaignData);
  };
  const handleCampaignChange = (e) => {
    const values = [...e.target.selectedOptions].map((opt) => parseInt(opt.value, 10));
    let selectedCampaign = [];
    values.forEach((item) => {
      const getCampaignName = CampaignList?.data.filter(
        (i) => parseInt(i.campaign_id, 10) === parseInt(item, 10),
      );
      const checkIdExist = formik.values.campaign_details.filter(
        (cam) => cam.campaign_id === item,
      );
      let addedData = [];
      if (checkIdExist?.length === 0) {
        addedData = {
          campaign_id: item,
          name: getCampaignName[0]?.campaign_name,
          recording: false,
          revenue_on: '',
          revenue: 0,
          call_length: 0,
          duplicate_call: false,
          duplicate_call_length: 0,
          purchased_did_numbers: [],
          start_call_length: 0,
        };
      } else {
        addedData = checkIdExist?.[0];
      }
      selectedCampaign = [...selectedCampaign, addedData];
    });
    formik.setFieldValue('campaign_details', selectedCampaign);
  };
  const formikHandleChange = (type, index, e) => {
    let val = e.target.value;
    if (
      type === 'revenue'
      || type === 'call_length'
      || type === 'duplicate_call_length'
    ) {
      val = val ? parseInt(val, 10) : 0;
    }
    if (type === 'duplicate_call') {
      val = e.target.checked;
    }
    const campaignData = [...formik.values.campaign_details];
    // Create a shallow copy of the object being modified
    const updatedItem = { ...campaignData[index], [type]: val };
    // Replace the object at the specific index
    campaignData[index] = updatedItem;
    if (type === 'renveue_on') {
      campaignData[index].revenue = '';
      campaignData[index].call_length = 0;
    } else if (type === 'dulicate_call') {
      campaignData[index].revenue = '';
      campaignData[index].duplicate_call_length = '';
    }
    campaignData[index][type] = val;
    formik.setFieldValue('campaign_details', campaignData);
  };
  useEffect(() => {
    if (data) {
      setServerData({
        ...data?.publisherData,
      });
    }
  }, [data]);
  const handleCancel = () => {
    dispatch(handleSearchParams({ page: 1 }));
    navigat('/publisher-list');
  };
  const handleEnableDisable = (e, type) => {
    const val = e.target.checked;
    if (type === 'campaign') {
      formik.setFieldValue('campaign_details', []);
    }
    formik.setFieldValue(type, val);
  };
  const handlePublisherSubmit = () => {
    if (Object.keys(formik.errors)?.length > 0) {
      const firstError = document.querySelector('.formik-error');
      if (firstError) {
        (firstError?.parentElement ?? firstError)?.scrollIntoView();
        formik.handleSubmit();
      }
    } else {
      formik.handleSubmit();
    }
  };
  const handleRecordingChange = (index, e) => {
    const val = e.target.checked;
    const campaignData = formik.values.campaign_details;
    campaignData[index].recording = val;
    formik.setFieldValue('campaign_details', campaignData);
  };
  return (
    <div className="container-fluid add-publisher-container">
      <PageHeader
          HeaderText="Manage Publisher"
          Breadcrumb={[
          { name: 'Publisher', navigate: '/publisher-list' },
          { name: id ? 'Edit Publisher' : 'Create Publisher', navigate: '' },
        ]}
      />
      <div className="col-12 p-0">
        <div className="card themeTabs body">
          <div className="card-tab-header">
            <ul className="nav nav-tabs-new">
              <li className="nav-item mr-1 active">
                <div className="nav-link active">
                  {id ? 'Edit Details' : 'Add Publisher '}
                </div>
              </li>
            </ul>
          </div>
          {dataLoading ? (
            <div className="div-center">
              <Loader />
            </div>
          ) : (
            <div className="tab-content">
              <form>
                <div className="row gy-3">
                  <div className="col-12 col-md-6">
                    <InputWithTooltip
                        name="firstname"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="First Name"
                        text="Enter the user's first name."
                        required
                    />
                    <ErrorMessageComponent
                        error={formik.errors.firstname}
                        touched={formik.touched.firstname}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <InputWithTooltip
                        name="lastname"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Last Name"
                        text="Enter the user's last name."
                        required
                    />
                    <ErrorMessageComponent
                        error={formik.errors.lastname}
                        touched={formik.touched.lastname}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <InputWithTooltip
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Email ID"
                        text="Enter a valid email address"
                        required
                        disabled={id}
                    />
                    <ErrorMessageComponent
                        error={formik.errors.email}
                        touched={formik.touched.email}
                    />
                  </div>
                  <div className="col-12">
                    <h6>Permissions</h6>
                  </div>
                  {/* <div className="col-12 mb-3 display-none">
                          <label className="mb-0">
                              Campaign
                              <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={(
                                      <Tooltip id="tooltip-top">
                                          Activate to set call limits for this route or target.
                                      </Tooltip>
                                  )}
                              >
                                  <i className="icon-question ml-1 align-middle" />

                              </OverlayTrigger>
                          </label>
                      </div> */}

                  <div className="col-12 col-md-3 align-self-center">
                    <label className="mb-0" htmlFor={name}>
                      Campaign
                      <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                          <Tooltip id="tooltip-top">Select the campaign for the Publisher usage.</Tooltip>
                        }
                      >
                        <i className="icon-question ml-1 align-middle" />
                      </OverlayTrigger>
                    </label>
                  </div>
                  <div className="col-12 col-md-7 align-self-center">
                    <div className="d-flex align-items-center gap-5 flex-wrap flex-md-nowrap">
                      <label className="switch flex-shrink-0 ms-0">
                        <input
                            type="checkbox"
                            id="campaign"
                            checked={formik?.values?.campaign}
                            name="campaign"
                            onChange={(e) => handleEnableDisable(e, 'campaign')}
                        />
                        <span className="slider round" />
                      </label>
                      {formik.values.campaign && (
                        <span>
                          Enabling the Campaign Module grants publishers
                          permission to access the campaigns and view them in
                          their reports.
                        </span>
                      )}
                    </div>
                  </div>
                  {formik.values.campaign && (
                    <>
                      <div className="col-12 col-md-6">
                        <div className="select-error-set form-data pr-0 pr-lg-2">
                          <TooltipContainer
                              label="Select Campaigns"
                              id="campaigns"
                              text="Identify the carrier from which this Advertiser originates."
                          >
                            <SearchableSelect
                                label="campaigns"
                                options={campaignList}
                                onChange={handleCampaignChange}
                                multipleValue={
                                formik?.values?.campaign_details.map((item) => parseInt(item.campaign_id, 10)) || []
                              }
                                name="campaign_details[]"
                                optionalFieldName={'campaign_name'}
                                multiple
                            />
                          </TooltipContainer>
                          {typeof formik.errors.campaign_details
                            === 'string' && (
                            <ErrorMessageComponent
                                error={formik.errors.campaign_details}
                                touched={formik.touched.campaign_details}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        {Array.isArray(formik.values.campaign_details)
                          && formik.values.campaign_details.length > 0
                          && formik.values.campaign_details?.map((item, key) => (
                            <div
                                className="campaign-subform-bg p-md-4 p-3 mt-3 row mx-0"
                                key={key}
                            >
                              <div className="col-12 col-md-2 pt-4">
                                {item.name}
                              </div>
                              <div className="col-12 col-md-10">
                                <div className="row gy-4">
                                  <div className="col-12 col-md-auto">
                                    <label>Call Recording</label>
                                    <div>
                                      <label className="switch ms-0 mt-2">
                                        <input
                                            type="checkbox"
                                            id={`${item.id}-id`}
                                            checked={
                                            formik.values.campaign_details[key]
                                              ?.recording
                                          }
                                            name="recording"
                                            onChange={(e) => handleRecordingChange(key, e)}
                                        />
                                        <span className="slider round toggle-width" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-3">
                                    <label>Revenue On</label>
                                    <div className="form-group">
                                      <select
                                          className="form-control bg-white"
                                          name={`campaign_details[${key}].revenue_on`}
                                          value={
                                          formik.values.campaign_details[key]
                                            ?.revenue_on
                                        }
                                          onChange={(e) => formikHandleChange(
                                            'revenue_on',
                                            key,
                                            e,
                                          )}
                                      >
                                        <option value="">Select</option>
                                        <option value="call_length">
                                          Call Length
                                        </option>
                                        <option value="call_successfully_connected">
                                          Call Successfully Connected.
                                        </option>
                                      </select>
                                      <ErrorMessageComponent
                                          error={
                                          formik.errors.campaign_details?.[key]
                                            ?.revenue_on
                                        }
                                          touched={
                                          formik.touched.campaign_details?.[key]
                                            ?.revenue_on
                                        }
                                      />
                                    </div>
                                  </div>
                                  {(formik.values.campaign_details[key]
                                    ?.revenue_on === 'call_length' || formik.values.campaign_details[key]
                                    ?.revenue_on === 'call_successfully_connected') && (
                                    <>
                                      <div className="col-auto">
                                        <div className="input-group flex-column">
                                          <label>Revenue</label>

                                          <div className="d-flex align-items-center gap-2">
                                            <div className="form-group">
                                              {user?.currency?.currency_symbol}
                                            </div>
                                            <div className="">
                                              <input
                                                  className="form-control w-100px bg-white"
                                                  value={
                                                  formik.values
                                                    .campaign_details[key]
                                                    ?.revenue || ''
                                                }
                                                  onChange={(e) => formikHandleChange(
                                                    'revenue',
                                                    key,
                                                    e,
                                                  )}
                                                  name={`campaign_details[${key}].revenue`}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <ErrorMessageComponent
                                            error={
                                            formik.errors.campaign_details?.[
                                              key
                                            ]?.revenue
                                          }
                                            touched={
                                            formik.touched.campaign_details?.[
                                              key
                                            ]?.revenue
                                          }
                                        />
                                      </div>
                                      {formik.values.campaign_details[key]?.revenue_on === 'call_length' && (
                                      <div className="col-auto">
                                        <div className="input-group flex-column">
                                          <label>Length</label>
                                          <div className="d-inline-flex">
                                            <input
                                                className="form-control w-100px bg-white"
                                                value={formik.values.campaign_details?.[key]?.start_call_length}
                                              // onChange={(e) => formikHandleChange('start_call_length', key, e)}
                                                onChange={(e) => {
                                                const { value } = e.target;
                                                if (/^\d*\.?\d*$/.test(value)) {
                                                  formikHandleChange(
                                                    'start_call_length',
                                                    key,
                                                    e,
                                                  );
                                                }
                                              }}
                                                name={`campaign_details[${key}].start_call_length`}
                                                type="string"
                                            />
                                            <div className="input-group-append ">
                                              <span
                                                  className="input-group-text bg-gray"
                                                  id="callLength-data"
                                              >
                                                Sec
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                          <ErrorMessageComponent
                                              error={
                                              formik.errors.campaign_details?.[
                                                key
                                              ]?.start_call_length
                                            }
                                              touched={
                                              formik.touched.campaign_details?.[
                                                key
                                              ]?.start_call_length
                                            }
                                          />
                                      </div>
                                      )}
                                    </>
                                  )}
                                  <div className="col-12 col-md-auto">
                                    <label>Duplicate Call</label>
                                    <div className="d-flex align-items-center gap-md-4 gap-3">
                                      <label
                                          className={`switch ms-0 flex-shrink-0 ${
                                          !item.duplicate_call ? 'mt-2' : ''
                                        }`}
                                      >
                                        <input
                                            type="checkbox"
                                            id={`${item.id}-duplicate-id`}
                                            checked={
                                            formik.values.campaign_details[key]
                                              ?.duplicate_call
                                          }
                                            name={`campaign_details[${key}].duplicate_call`}
                                            onChange={(e) => formikHandleChange(
                                              'duplicate_call',
                                              key,
                                              e,
                                            )}
                                        />
                                        <span className="slider round toggle-width" />
                                      </label>
                                      {item.duplicate_call && (
                                        <div className="input-group flex-nowrap">
                                          <input
                                              className="form-control w-100px bg-white"
                                              value={
                                              formik.values.campaign_details[
                                                key
                                              ]?.duplicate_call_length
                                            }
                                              onChange={(e) => {
                                              const { value } = e.target;
                                              if (/^\d*$/.test(value)) {
                                                formikHandleChange(
                                                  'duplicate_call_length',
                                                  key,
                                                  e,
                                                );
                                              }
                                            }}
                                              name={`campaign_details[${key}].duplicate_call_length`}
                                              type="string"
                                          />
                                          <div className="input-group-append">
                                            <span
                                                className="input-group-text bg-gray"
                                                id="callLength-data"
                                            >
                                              Hr
                                            </span>
                                          </div>
                                        </div>
                                     )}
                                    </div>
                                     <ErrorMessageComponent
                                         error={
                                         formik.errors.campaign_details?.[
                                           key
                                         ]?.duplicate_call_length
                                       }
                                         touched={
                                         formik.touched.campaign_details?.[
                                           key
                                         ]?.duplicate_call_length
                                       }
                                     />
                                  </div>

                                  <div className="col-12 col-md-8">
                                    <div className="select-error-set form-data pr-0 pr-lg-2">
                                      <TooltipContainer
                                          label="Select DID Number"
                                          id="purchased_did_numbers"
                                          text="Purchase new DID numbers for call routing or campaign usage."
                                      >
                                        <div>
                                          <SearchableSelect
                                              label="DID"
                                              options={campaignList?.filter((d) => parseInt(d.id, 10) === parseInt(item.campaign_id, 10))?.[0]?.purchased_did_numbers}
                                              onChange={(e) => handleDidChange(e, key)}
                                              multipleValue={
                                              item.purchased_did_numbers.map(
                                                (i) => i,
                                              ) || []
                                            }
                                              name={`campaign_details[${key}].purchased_did_numbers`}
                                              optionalFieldName={
                                              'purchased_did_number'
                                            }
                                              multiple
                                          />
                                        </div>
                                      </TooltipContainer>
                                      <ErrorMessageComponent
                                          error={
                                          formik.errors.campaign_details?.[key]
                                            ?.purchased_did_numbers
                                        }
                                          touched={
                                          formik.touched.campaign_details?.[key]
                                            ?.purchased_did_numbers
                                        }
                                      />
                                      {(campaignList?.filter((d) => parseInt(d.id, 10) === parseInt(item.campaign_id, 10))?.[0]?.purchased_did_numbers)?.length
                                        === 0 && (
                                        <p>
                                          There is no purchased DID numbers
                                          number has been assigned in this
                                          campaign, kindly add the purchased DID
                                          numbers number from Campaign or number
                                          module.
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-12" />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}

                  <div className="col-12 col-md-3 align-self-center">
                    <label className="mb-0" htmlFor={name}>
                      Create DID
                      <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Click to generate a new Direct Inward Dialing (DID) number for routing calls.</Tooltip>}
                      >
                        <i className="icon-question ml-1 align-middle" />
                      </OverlayTrigger>
                    </label>
                  </div>
                  <div className="col-12 col-md-7 align-self-center">
                    <div className="d-flex align-items-center gap-5 flex-wrap flex-md-nowrap">
                      <label className="switch flex-shrink-0 ms-0">
                        <input
                            type="checkbox"
                            id="create_did"
                            checked={formik?.values?.create_did}
                            name="create_did"
                            onChange={(e) => handleEnableDisable(e, 'create_did')}
                        />
                        <span className="slider round" />
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 align-self-center">
                    <label className="mb-0" htmlFor="blacklist">
                      BlackList
                      <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                          <Tooltip id="tooltip-top">Add a phone number to the blacklist to prevent future calls.</Tooltip>
                        }
                      >
                        <i className="icon-question ml-1 align-middle" />
                      </OverlayTrigger>
                    </label>
                  </div>
                  <div className="col-12 col-md-7 align-self-center">
                    <div className="d-flex align-items-center gap-5 flex-wrap flex-md-nowrap">
                      <label className="switch flex-shrink-0 ms-0">
                        <input
                            type="checkbox"
                            id="blacklist"
                            checked={formik.values.blacklist}
                            name="blacklist"
                            onChange={(e) => {
                            const val = e.target.checked;
                            formik.setFieldValue('blacklist', val);
                            if (!val) {
                              formik.setFieldValue('blacklist_details', []);
                            }
                          }}
                        />
                        <span className="slider round" />
                      </label>
                      {formik.values.blacklist && (
                        <span>
                          Provides access to create and manage the blacklist of
                          restricted numbers.
                        </span>
                      )}
                    </div>
                  </div>
                  {formik.values.blacklist && (
                    <div className="col-12">
                      <div className="live-call-dropdown col-12 col-md-4 gap-3">
                        {PermissionList?.data?.blacklistArray.map(
                          (item, index) => (
                            <div
                                key={index}
                                className="d-inline-flex align-items-center gap-3"
                            >
                              <input
                                  type="checkbox"
                                  id={`blacklist-${index}`}
                                  checked={formik.values.blacklist_details.some(
                                  (i) => i.value === item.value,
                                )}
                                // name={`blacklist_details[${index}]`}
                                  name={'blacklist_details'}
                                  value={item.value}
                                  onChange={(e) => {
                                  const { checked } = e.target;
                                  const updatedBlacklist = checked
                                    ? [
                                        ...formik.values.blacklist_details,
                                        {
                                          name: item.name,
                                          value: item.value,
                                        },
                                      ]
                                    : formik.values.blacklist_details.filter(
                                        (i) => i.value !== item.value,
                                      );
                                  formik.setFieldValue(
                                    'blacklist_details',
                                    updatedBlacklist,
                                  );
                                }}
                              />
                              <span className="ml-2">{item.name}</span>
                            </div>
                          ),
                        )}
                        <ErrorMessageComponent
                            error={formik.errors.blacklist_details}
                            touched={formik.touched.blacklist_details}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-12">
                    <h6>Reports</h6>
                  </div>

                  <div className="col-12 col-md-3 align-self-center">
                    <label className="mb-0" htmlFor={name}>
                      Live Call
                      <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Monitor live calls in real-time.</Tooltip>}
                      >
                        <i className="icon-question ml-1 align-middle" />
                      </OverlayTrigger>
                    </label>
                  </div>
                  <div className="col-12 col-md-7 align-self-center">
                    <div className="d-flex align-items-center gap-5 flex-wrap flex-md-nowrap">
                      <label className="switch flex-shrink-0 ms-0">
                        <input
                            type="checkbox"
                            id="live_call"
                            checked={formik?.values?.live_call}
                            name="live_call"
                            onChange={(e) => {
                            const val = e.target.checked;
                            formik.setFieldValue('live_call', val);
                            formik.setFieldValue('live_call_details', []);
                          }}
                        />
                        <span className="slider round" />
                      </label>
                      {formik.values.live_call && (
                        <span>
                          Enables real-time call monitoring and interactions
                        </span>
                      )}
                    </div>
                  </div>
                  {formik.values.live_call && (
                    <div className="col-12">
                      <div className="live-call-dropdown col-12 col-md-4 gap-3">
                        {PermissionList?.data?.liveCallArray.map(
                          (item, index) => (
                            <div
                                key={index}
                                className="d-inline-flex align-items-center gap-3"
                            >
                              <input
                                  type="checkbox"
                                  id={`live_call_details-${index}`}
                                  checked={formik?.values?.live_call_details
                                  ?.map((i) => i.value)
                                  ?.includes(item?.value)}
                                  name="live_call_details"
                                  value={item?.value} // Set the value of each checkbox to the item
                                  onChange={(e) => {
                                  const { checked } = e.target;
                                  const newListCallList = checked
                                    ? [
                                        ...formik.values.live_call_details,
                                        {
                                          name: item?.name,
                                          value: item?.value,
                                        },
                                      ] // Add item if checked
                                    : formik.values.live_call_details.filter(
                                        (callItem) => callItem.value !== item.value,
                                      ); // Remove item if unchecked
                                  formik.setFieldValue(
                                    'live_call_details',
                                    newListCallList,
                                  );
                                }}
                              />
                              <span className="ml-2">{item.name}</span>
                            </div>
                          ),
                        )}
                        <ErrorMessageComponent
                            error={formik.errors.live_call_details}
                            touched={formik.touched.live_call_details}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-12 col-md-3 align-self-center">
                    <label className="mb-0" htmlFor={name}>
                      CDR
                      <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Access detailed records of past calls, including duration, cost, and call status</Tooltip>}
                      >
                        <i className="icon-question ml-1 align-middle" />
                      </OverlayTrigger>
                    </label>
                  </div>
                  <div className="col-12 col-md-7 align-self-center">
                    <div className="d-flex align-items-center gap-5 flex-wrap flex-md-nowrap">
                      <label className="switch flex-shrink-0 ms-0">
                        <input
                            type="checkbox"
                            id="cdr"
                            checked={formik?.values?.cdr}
                            name="cdr"
                            onChange={(e) => {
                            const val = e.target.checked;
                            formik.setFieldValue('cdr', val);
                            formik.setFieldValue('cdr_details', []);
                          }}
                        />
                        <span className="slider round" />
                      </label>
                      {formik.values.cdr && (
                        <span>
                          Grants access to view and analyze cll logs and details
                        </span>
                      )}
                    </div>
                  </div>
                  {formik.values.cdr && (
                    <div className="col-12 col-lg-6 col-md-8 col-sm-6">
                      <div className="gap-3 cdr-dropdown-container">
                        {PermissionList?.data?.cdrArray.map((item, index) => (
                          <div
                              key={index}
                              className="d-inline-flex align-items-center gap-3"
                          >
                            <input
                                type="checkbox"
                                id={`cdrList-${index}`}
                                checked={formik?.values?.cdr_details
                                ?.map((i) => i.value)
                                ?.includes(item?.value)}
                                name="cdr_details"
                                value={item?.value} // Set the value of each checkbox to the item
                                onChange={(e) => {
                                const { checked } = e.target;
                                const newCdrList = checked
                                  ? [
                                      ...formik.values.cdr_details,
                                      {
                                        name: item?.name,
                                        value: item?.value,
                                      },
                                    ] // Add item if checked
                                  : formik.values.cdr_details.filter(
                                      (cdrItem) => cdrItem.value !== item.value,
                                    ); // Remove item if unchecked
                                formik.setFieldValue('cdr_details', newCdrList);
                              }}
                            />
                            <span className="ml-2">{item.name}</span>
                          </div>
                        ))}
                        <ErrorMessageComponent
                            error={formik.errors.cdr_details}
                            touched={formik.touched.cdr_details}
                        />
                      </div>
                    </div>
                  )}
                  {/* <div className="col-12 col-md-6 select-error-set form-data pr-0 pr-lg-2">
                                          <TooltipContainer label="Select Carriers" id="form_carrier" text="Identify the carrier from which this Advertiser originates." required>
                                              <SearchableSelect
                                                  label="carriers"
                                                  options={campaignList?.data}
                                                  onChange={handleCarrierChange}
                                                  multipleValue={formik?.values?.cam}
                                                  name="from_carrier[]"
                                                  multiple
                                              />
                                          </TooltipContainer>
                                          <ErrorMessageComponent error={formik.errors.from_carrier} touched={formik.touched.from_carrier} />
                                          <ErrorMessageComponent error={formik.errors.from_carrier_info} touched={formik.touched.from_carrier_info} />
                                          {/* Accordin select option loop :: BEGIN */}
                  {/* <div className="w-100 mb-4">
                                              <div className="table-responsive pt-0 w-100">
                                                  {selectedCarrierInfo?.length > 0 && (
                                                      <table className="table without-border w-100">
                                                          <thead>
                                                              <tr>
                                                                  <th>
                                                                      <h6 className="mb-0 text-center">CARRIER</h6>
                                                                  </th>
                                                                  <th>
                                                                      <h6 className="mb-0 text-center">
                                                                          Priority
                                                                          <span className="text-red">*</span>
                                                                      </h6>
                                                                  </th>
                                                                  <th>
                                                                      {' '}
                                                                  </th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              {selectedCarrierInfo.map((carrier, index) => (
                                                                  <>
                                                                      <tr key={index}>
                                                                          <td className="text-center">
                                                                              {carrier.name}
                                                                          </td>
                                                                          <td className="">
                                                                              <div className="amount-section">
                                                                                  <div className="form-group mb-0">
                                                                                      <input
                                                                                          className="form-control"
                                                                                          placeholder="Priority"
                                                                                          type="text"
                                                                                          name="priority"
                                                                                          value={carrier.priority}
                                                                                          onChange={(e) => handlePriorityChange(e, index)}
                                                                                      />
                                                                                  </div>
                                                                              </div>
                                                                          </td>
                                                                          <td>
                                                                              <button className="btn btn-sm btn-danger mb-1" onClick={() => handleDeleteCarrier(carrier.id)}>
                                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="13.2" height="17" viewBox="0 0 13.2 17" title="Delete">
                                                                                      <path id="Union_105" data-name="Union 105" d="M14370.7,11745a3.5,3.5,0,0,1-3.5-3.507v-7.326a2.076,2.076,0,0,1,.9-3.959h1.512a2.208,2.208,0,0,1,2.208-2.208h1.588a2.206,2.206,0,0,1,2.2,2.208h1.513a2.079,2.079,0,0,1,.888,3.959v7.326a3.508,3.508,0,0,1-3.512,3.507Zm-2.273-3.507a2.269,2.269,0,0,0,2.273,2.266h3.8a2.285,2.285,0,0,0,2.271-2.266v-7.12h-8.34Zm-1.187-9.2a.846.846,0,0,0,.837.844h9.044a.844.844,0,1,0,0-1.688h-9.044A.846.846,0,0,0,14367.241,11732.292Zm3.587-2.083h3.533a.974.974,0,0,0-.971-.971h-1.592A1,1,0,0,0,14370.828,11730.209Zm3.28,11.758v-6.023h1.241v6.023Zm-2.129,0v-6.023h1.241v6.023Zm-2.118,0v-6.023h1.237v6.023Z" transform="translate(-14366 -11728.001)" />
                                                                                  </svg>
                                                                              </button>
                                                                          </td>

                                                                      </tr>
                                                                      <tr>
                                                                          <td>{}</td>
                                                                          <td colSpan={3}>
                                                                              {carrier.priority_error && (
                                                                                  <p className="text-error">{carrier.priority_error}</p>
                                                                              )}
                                                                          </td>
                                                                      </tr>

                                                                  </>
                                                              ))}
                                                          </tbody>
                                                      </table>
                                                  )}
                                              </div>
                                          </div>
                                                                              </div> */}
                  <div className="col-12">
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handlePublisherSubmit}
                        disabled={formik.isSubmitting}
                    >
                      {id ? 'Update' : 'Create'}
                    </button>
                    <button
                        className="btn btn-secondary ml-3"
                        type="button"
                        onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
AddPublisher.propTypes = {
  handlePostRequest: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool.isRequired,
};

export default withPostContainer(AddPublisher, '/publisher/');
