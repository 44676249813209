/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import LogoIcon from '../assets/images/logo-black-icon.svg';
// import LogoWhiteIcon from '../assets/images/logo-white-icon.svg';
import LoaderIcon from '../assets/images/loader-icon.png';

const CampaignLoader = ({ doNotScroll, type }) => {
    useEffect(() => {
        if (!doNotScroll) window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container-fluid">
            <div className="row clearfix">
                <div className="col-lg-12">
                    <div className="page-loader-wrapper">
                        <div className="loader">
                            <div className={type !== 'export' ? 'campaign-div-center' : 'export-loader'}>
                                <div className="main-loader">
                                    <div className="loader-icon-container">
                                        <img src={LoaderIcon} alt="Loader Icon" className="loader-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CampaignLoader;

CampaignLoader.propTypes = {
    doNotScroll: PropTypes.bool.isRequired,
    type: PropTypes.string,
};
